export const data = '';

const openIndexedDb = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('smartbot', 1);

    request.onupgradeneeded = event => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains('calls')) {
        const store = db.createObjectStore('calls', {
          keyPath: 'id',
          autoIncrement: true,
        });
        store.createIndex('exten', 'exten', { unique: false });
      }
    };

    request.onsuccess = event => {
      const db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    request.onerror = event => {
      reject(new Error('Error opening database'));
    };
  });
};

export interface IIndexedDbIncomingCallData {
  exten: string;
  number: string;
  direction: 'incomming';
  date: string;
  e64_data?: { [key: string]: string | number}[];
  campaignId?: string;
  contactId?: string;
  contactName?: string;
  uniqueId?: string;
  tag?: string
  info?: string,
  // this property 
  newContacName?: string;
  notes?: string
}

export interface IIndexedDbOutgoingCallData {
  exten: string;
  number: string;
  direction: 'outgoing';
  date: string;

  e64_data?: { [key: string]: string | number}[];
  campaignId?: string;
  contactId?: string;
  contactName?: string;
  uniqueId?: string;
  tag?: string
  info?: string,
  // this property 
  newContacName?: string;
  notes?: string
}

export type TIndexedDbCallData =
  | IIndexedDbIncomingCallData
  | IIndexedDbOutgoingCallData;

export function addDataToIndexedDb(data: TIndexedDbCallData) {
  openIndexedDb().then(db => {
    const transaction = db.transaction('calls', 'readwrite');
    const store = transaction.objectStore('calls');
    store.add(data);

    transaction.oncomplete = () => {
      console.log('Data added successfully');
    };

    transaction.onerror = () => {
      console.error('Error adding data');
    };
  });
}

export function updateDataToIndexedDb(data: TIndexedDbCallData) {
  openIndexedDb().then(db => {
    const transaction = db.transaction('calls', 'readwrite');
    const store = transaction.objectStore('calls');
    store.put(data);

    transaction.oncomplete = () => {
      console.log('Data updated successfully');
    };

    transaction.onerror = () => {
      console.error('Error updating data');
    };
  });
}

export function getDataByIndexIndexedDb(
  indexValue: string,
  limit = 100,
): Promise<TIndexedDbCallData[]> {
  return new Promise((resolve, reject) => {
    openIndexedDb()
      .then(db => {
        const transaction = db.transaction('calls', 'readwrite');
        const store = transaction.objectStore('calls');
        const index = store.index('exten');

        const results: TIndexedDbCallData[] = [];
        let count = 0;

        const request = index.openCursor(IDBKeyRange.only(indexValue), 'prev');

        request.onsuccess = (event: Event) => {
          const cursor = (event.target as IDBRequest<IDBCursorWithValue>)
            .result;
          if (cursor && count < limit) {
            results.push(cursor.value);
            count++;
            cursor.continue();
          } else if (cursor) {
            const deleteRequest = store.delete(cursor.value.id);
            deleteRequest.onsuccess = () => {
              console.log(`Deleted record with id: ${cursor.value.id}`);
              cursor.continue();
            };

            deleteRequest.onerror = error => {
              console.error(`Failed to delete record: ${error}`);
              cursor.continue();
            };
          } else {
            resolve(results);
          }
        };

        request.onerror = () => {
          reject(new Error('Error fetching data by index'));
        };

        transaction.onabort = () => {
          reject(new Error('Transaction aborted'));
        };
      })
      .catch(error => {
        reject(new Error('Error opening database: ' + error));
      });
  });
}

function getData(id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    openIndexedDb().then(db => {
      const transaction = db.transaction('calls', 'readonly');
      const store = transaction.objectStore('calls');
      const request = store.get(id);

      request.onsuccess = event => {
        resolve((event.target as IDBRequest).result);
      };

      request.onerror = () => {
        reject(new Error('Error fetching data'));
      };
    });
  });
}
