import WaCampaignReportItem from 'components/Report/organisms/waCampaignReportItem';
import { lightBackgroundBlue, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { IScenarioGeneralData } from 'library/interfaces/scenariosInterfaces';
import { IWaCampaign } from 'library/interfaces/whatsappCampaignInterfaces';
import {
  getToday_yyyymmdd,
  isFirstDateAfterToSecondDate,
  nDaysBeforeNow_LocalTime_formatISO,
} from 'library/services/dateServices';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getScenarios, getWACampaignsAction } from 'store/actions';
import styled from 'styled-components';

interface IWhatsAppCampaignProps {
  reportTypeId: string;
}
const WhatsAppCampaign: FC<IWhatsAppCampaignProps> = ({ reportTypeId }) => {
  const dispatch = useDispatch();
  const scenarios: IScenarioGeneralData[] = useAppSelector(
    store => store.scenarios.scenarios,
  );
  const { campaigns } = useAppSelector(store => store.waCampaigns);
  const owner = useAppSelector(store => store.authUserData.owner);

  const [startDate, setStartDate] = useState(
    nDaysBeforeNow_LocalTime_formatISO(31).substring(0, 10),
  );
  const [endDate, setEndDate] = useState(getToday_yyyymmdd());
  const [selectedScenario, setSelectedScenario] = useState('*');

  const campaignsToRender = useMemo(() => {
    if (owner === 'VARLIX') {
      const varlixCampaign: IWaCampaign[] = [
        {
          id: 'varlix_prod_01',
          name: 'varlix_prod_01',
          status: 'completed',
          source: '59899001998',
          active: true,
          template_id: '37706457-ec66-4819-9ce6-558371ffe376',
          scenario_id: 'fb2ea4a1-c494-4776-a9d7-b132c7ef95db',
          app_id: '649110c9-ac25-4cf1-87db-d4972b8a7e59',
          contacts_count: 194,
          dispatched_count: 194,
          dispatched_error: 0,
          dispatched_enqueued: 193,
          dispatched_failed: 0,
          dispatched_sent: 163,
          dispatched_delivered: 160,
          dispatched_read: 127,
          dispatched_replied: 44,
          created_at: '',
          updated_at: '2024-09-27T23:36:07.472Z',
          color: '',
          contacts: [],
          contactLoad: undefined,
          isLoading: false
        },
      ];
      return varlixCampaign
    }
    return campaigns.filter(campaign => {
      return (
        (selectedScenario === campaign.scenario_id ||
          selectedScenario === '*') &&
        isFirstDateAfterToSecondDate(campaign.created_at, startDate) &&
        isFirstDateAfterToSecondDate(
          endDate + 'T23:59:59.999Z',
          campaign.created_at,
        )
      );
    });
  }, [campaigns, startDate, endDate, selectedScenario]);

  useEffect(() => {
    if (campaigns.length === 0) {
      dispatch(getWACampaignsAction());
    }
    if (scenarios.length > 0) return;
    dispatch(getScenarios());
  }, []);

  return (
    <Cont>
      <Title>Reporte de Campañas de WhatsApp</Title>
      <BlueCont>
        <Form>
          <FormGroup>
            <label htmlFor="since">Desde:</label>
            <input
              type="date"
              id="since"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="until">Hasta:</label>
            <input
              type="date"
              id="until"
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="scenario">Escenario:</label>
            <select
              id="scenario"
              value={selectedScenario}
              onChange={e => setSelectedScenario(e.target.value)}
            >
              <option value="*">Todos los escenarios</option>
              <option value="">Sin escenario</option>
              {scenarios.map((scenario, index) => {
                return (
                  <option key={index} value={scenario.id}>
                    {scenario.name}
                  </option>
                );
              })}
            </select>
          </FormGroup>
        </Form>
      </BlueCont>
      <BlueCont>
        <GridCont>
          <div>Campaña</div>
          <div>Escenario</div>
          <Date>Fecha de creación</Date>
          <div></div>
        </GridCont>
        {campaignsToRender.map((camp, index) => {
          if(!camp.active) return
          return (
            <WaCampaignReportItem
              key={index}
              waCampaign={camp}
              scenarios={scenarios}
            />
          );
        })}
      </BlueCont>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled(Cont)`
  margin-top: 20px;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  /* grid-gap: 20px; */
  background-color: ${lightBackgroundBlue};
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    width: 140px;
  }
`;
const SelectCont = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.label<ILabel>`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin: ${props => props.margin};
`;
const CampaignSelect = styled.select`
  flex-grow: 1;
  max-width: 500px;
  margin-right: 20px;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const Form = styled.div`
  display: flex;
  padding: 0 20px;
`;
const FormGroup = styled.div`
  margin-right: 20px;

  font-size: 0.9rem;

  & > label {
    margin: 0;
    margin-right: 10px;
  }
  & > input {
    padding: 2px 0 0px;
    text-align: center;
    border-radius: 10px;
    background-color: white;
    border: 2px solid ${primary};
  }
  & > select {
    padding: 1px 0;
    /* text-align: center; */
    border-radius: 10px;
    background-color: white;
    border: 2px solid ${primary};
  }
`;
const GridCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 200px 200px;
  padding: 0 20px;
`;
const Date = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;
interface ILabel {
  margin?: string;
}
export default WhatsAppCampaign;
