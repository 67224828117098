import React, { FC } from 'react';
import styled from 'styled-components';
import InfoCont from '../atoms/infoCont';
import Title from '../atoms/title';
import Text from '../atoms/text';

interface IProblemasConAutoExpresoProps {}
const ProblemasConAutoExpreso: FC<IProblemasConAutoExpresoProps> = ({}) => {
  return (
    <InfoCont>
      <Title>Problemas con Autoexpreso</Title>
      <Text>
        En este caso su situación está relacionada con su cuenta de Autoexpreso.
        Metropistas no gestiona las cuentas de Autoexpreso, solo trabajamos la
        conservación y mantenimiento de las autopistas. El proceso de cobro
        reclamo de peaje adeudado, solicitar un recurso de revisión, o cualquier
        asunto de pago que se haya realizado a través de las plataformas de
        AutoExpreso, deberá comunicarse al centro de llamadas de AutoExpreso al
        1-888-688-1010, en horario de lunes a viernes de 8:00am a 7:00pm y
        sábados de 8:00am a 2:00pm. También podrá enviar un correo electrónico a
        la siguiente dirección servicioautoexpreso@pagodepeajes.pr.gov.
      </Text>
      <Text>
        Con gusto le redirigiré al call center de Autoexpreso para que puedan
        asistirle.
      </Text>
      <Text danger>
        Transfiere la llamada. Provéele el número para ocasiones futuras
        (1-888-688-1010).{' '}
      </Text>
      <Text danger>(DTOP)</Text>
      <Text>
        Departamento de Transportación y Obras Públicas (DTOP) está preparado
        para orientar a los ciudadanos en cuanto a los pasos o dudas que puedan
        surgir durante el proceso de pago a través de la aplicación de CESCO
        Digital. Además, atenderán cualquier reclamo por pago duplicado o pagos
        realizados a través de la aplicación de CESCO Digital, podrán
        comunicarse al centro de llamadas 1-833-938-6777 en horarios de lunes a
        viernes de 8:00am a 6:00pm y sábados de 8:00am a 2:00pm. También podrá,
        enviar un correo electrónico a soporte@pagodepeajes.pr.gov.
      </Text>
    </InfoCont>
  );
};

export default ProblemasConAutoExpreso;
