import { IGupshupAppTemplate } from 'library/interfaces/whatsappCampaignInterfaces';
import {
  DELETE_LIVE_CONVERSATION_SESSION,
  DELETE_LIVE_CONVERSATION_SESSION_FAIL,
  DELETE_LIVE_CONVERSATION_SESSION_SUCCESS,
  GET_AGENTS_CONNECTED_TO_WA,
  GET_AGENTS_CONNECTED_TO_WA_FAIL,
  GET_AGENTS_CONNECTED_TO_WA_SUCCESS,
  GET_ALL_SESSIONS_FROM_MONITOR,
  GET_ALL_SESSIONS_FROM_MONITOR_FAIL,
  GET_ALL_SESSIONS_FROM_MONITOR_SUCCESS,
  GET_LIVE_CONVERSATION_SESSION_LOGS,
  GET_LIVE_CONVERSATION_SESSION_LOGS_FAIL,
  GET_LIVE_CONVERSATION_SESSION_LOGS_SUCCESS,
  GET_LIVE_CONVERSATION_SESSION_MESSAGES,
  GET_LIVE_CONVERSATION_SESSION_MESSAGES_FAIL,
  GET_LIVE_CONVERSATION_SESSION_MESSAGES_SUCCESS,
  GET_SESSIONS_FROM_MONITOR,
  GET_SESSIONS_FROM_MONITOR_FAIL,
  GET_SESSIONS_FROM_MONITOR_SUCCESS,
  IGetAgentsConnectedToWa,
  IGetAgentsConnectedToWaFail,
  IGetAgentsConnectedToWaSuccess,
  IGetAllSessionsFromMonitor,
  IGetAllSessionsFromMonitorFail,
  IGetAllSessionsFromMonitorSuccess,
  IGetLiveConversationSessionLogs,
  IGetLiveConversationSessionLogsFail,
  IGetLiveConversationSessionLogsSuccess,
  IGetLiveConversationSessionMessages,
  IGetLiveConversationSessionMessagesFail,
  IGetLiveConversationSessionMessagesSuccess,
  IGetSessionsFromMonitor,
  IGetSessionsFromMonitorFail,
  IGetSessionsFromMonitorSuccess,
  IPutLiveConversationTransferSession,
  IPutLiveConversationTransferSessionFail,
  IPutLiveConversationTransferSessionSuccess,
  IPutRestoreWaSession,
  IPutRestoreWaSessionFail,
  IPutRestoreWaSessionSuccess,
  IdeleteLiveConversationSession,
  IdeleteLiveConversationSessionFail,
  IdeleteLiveConversationSessionSuccess,
  PUT_LIVE_CONVERSATION_TRANSFER_SESSION,
  PUT_LIVE_CONVERSATION_TRANSFER_SESSION_FAIL,
  PUT_LIVE_CONVERSATION_TRANSFER_SESSION_SUCCESS,
  PUT_RESTORE_WA_SESSION,
  PUT_RESTORE_WA_SESSION_FAIL,
  PUT_RESTORE_WA_SESSION_SUCCESS,
} from './actionTypes';
import { IError } from 'store/reports/reducer';
import {
  ILiveConversationAgents,
  ILiveConversationSession,
  ILiveConversationSessionLogs,
  ILiveConversationsCampaign,
} from './reducer';
import { IUser } from 'library/interfaces/crdInterfaces';

export const getLiveConversationSessionMessagesAction = (
  channel: string,
  source: string,
  sessionId: string,
  templates: IGupshupAppTemplate[],
): IGetLiveConversationSessionMessages => ({
  type: GET_LIVE_CONVERSATION_SESSION_MESSAGES,
  payload: {
    channel,
    source,
    sessionId,
    templates,
  },
});

export const getLiveConversationSessionMessagesSuccess = (
  message: { [key: string]: string | number }[],
): IGetLiveConversationSessionMessagesSuccess => ({
  type: GET_LIVE_CONVERSATION_SESSION_MESSAGES_SUCCESS,
  payload: { message },
});

export const getLiveConversationSessionMessagesFail = (
  error: IError,
): IGetLiveConversationSessionMessagesFail => ({
  type: GET_LIVE_CONVERSATION_SESSION_MESSAGES_FAIL,
  paylaod: { error },
});

export const getLiveConversationsSessionLogsAction = (
  sessionId: string,
): IGetLiveConversationSessionLogs => ({
  type: GET_LIVE_CONVERSATION_SESSION_LOGS,
  payload: { sessionId },
});
export const getLiveConversationsSessionLogsSuccess = (
  logs: ILiveConversationSessionLogs[],
): IGetLiveConversationSessionLogsSuccess => ({
  type: GET_LIVE_CONVERSATION_SESSION_LOGS_SUCCESS,
  payload: { logs },
});
export const getLiveConversationSessionLogsFail = (
  error: IError,
): IGetLiveConversationSessionLogsFail => ({
  type: GET_LIVE_CONVERSATION_SESSION_LOGS_FAIL,
  paylaod: { error },
});

export const putLiveConversationTranferSessionAction = (
  sessionId: string,
  agentId: string,
): IPutLiveConversationTransferSession => ({
  type: PUT_LIVE_CONVERSATION_TRANSFER_SESSION,
  payload: { agentId, sessionId },
});
export const putLiveConversationTranferSessionSuccess = (
  session: ILiveConversationSession,
): IPutLiveConversationTransferSessionSuccess => ({
  type: PUT_LIVE_CONVERSATION_TRANSFER_SESSION_SUCCESS,
  payload: { session },
});
export const putLiveConversationTransferSessionFail = (
  error: IError,
  sessionId: string,
): IPutLiveConversationTransferSessionFail => ({
  type: PUT_LIVE_CONVERSATION_TRANSFER_SESSION_FAIL,
  payload: { error, sessionId },
});

export const getSessionFromMonitorAction = (
  lastKey: string | null,
  isActive?: boolean,
  campaign?: string,
  assignedTo?: string,
): IGetSessionsFromMonitor => ({
  type: GET_SESSIONS_FROM_MONITOR,
  payload: { isActive, lastKey, campaign, assignedTo },
});
export const getSessionFromMonitorSuccess = (
  sessions: ILiveConversationSession[],
  lastKey: string | null,
  campaign?: ILiveConversationsCampaign,
): IGetSessionsFromMonitorSuccess => ({
  type: GET_SESSIONS_FROM_MONITOR_SUCCESS,
  payload: { sessions, lastKey, campaign },
});
export const getSessionFromMonitorFail = (
  error: IError,
): IGetSessionsFromMonitorFail => ({
  type: GET_SESSIONS_FROM_MONITOR_FAIL,
  payload: { error },
});

/**
 * Para la vista de VARLIX
 * Trae todas las conversaciones de los agentes
 */
export const getAllSessionsFromMonitor = (
  users: IUser[],
): IGetAllSessionsFromMonitor => ({
  type: GET_ALL_SESSIONS_FROM_MONITOR,
  payload: { users },
});

/**
 * 
 * @param sessions todas las sesiones de los agentes
 * @param lastKey debe ser siempre null
 * @param campaign se queda como undefined, se coloca para mantener el reducer
 * @returns 
 */

export const getAllSessionsFromMonitorSuccess = (
  sessions: ILiveConversationSession[],
  lastKey: string | null,
  campaign?: ILiveConversationsCampaign,
): IGetAllSessionsFromMonitorSuccess => ({
  type: GET_ALL_SESSIONS_FROM_MONITOR_SUCCESS,
  payload: { sessions, lastKey },
});

export const getAllSessionsFromMonitorFail = (
  error: IError,
): IGetAllSessionsFromMonitorFail => ({
  type: GET_ALL_SESSIONS_FROM_MONITOR_FAIL,
  payload: { error },
});

export const deleteLiveConversationSessionAction = (
  sessionId: string,
): IdeleteLiveConversationSession => ({
  type: DELETE_LIVE_CONVERSATION_SESSION,
  payload: { sessionId },
});
export const deleteLiveConversationSessionSuccess = (
  sessionId: string,
): IdeleteLiveConversationSessionSuccess => ({
  type: DELETE_LIVE_CONVERSATION_SESSION_SUCCESS,
  payload: { sessionId },
});
export const deleteLiveConversationSessionFail = (
  error: IError,
): IdeleteLiveConversationSessionFail => ({
  type: DELETE_LIVE_CONVERSATION_SESSION_FAIL,
  payload: { error },
});

export const getAgentsConnectedToWaAction = (
  campaignId: string,
): IGetAgentsConnectedToWa => ({
  type: GET_AGENTS_CONNECTED_TO_WA,
  payload: { campaignId },
});
export const getAgentsConnectedToWaSuccess = (
  agents: ILiveConversationAgents,
): IGetAgentsConnectedToWaSuccess => ({
  type: GET_AGENTS_CONNECTED_TO_WA_SUCCESS,
  payload: { agents },
});
export const getAgentsConnectedToWaFail = (): IGetAgentsConnectedToWaFail => ({
  type: GET_AGENTS_CONNECTED_TO_WA_FAIL,
});

export const putRestoreWaSessionAction = (
  sessionId: string,
): IPutRestoreWaSession => ({
  type: PUT_RESTORE_WA_SESSION,
  payload: { sessionId },
});
export const putRestoreWaSessionSuccess = (
  sessionId: string,
): IPutRestoreWaSessionSuccess => ({
  type: PUT_RESTORE_WA_SESSION_SUCCESS,
  payload: { sessionId },
});
export const putRestoreWaSessionFail = (
  error: IError,
): IPutRestoreWaSessionFail => ({
  type: PUT_RESTORE_WA_SESSION_FAIL,
  payload: { error },
});
