import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  lightBackgroundBlue,
  lightGreen,
} from 'library/colors';
import { useTranslation } from 'react-i18next';
import DropZone from '../atoms/dropzone';

export interface IFile {
  fileName: string;
  sheetsName: string[];
  sheets: {
    name: string;
    columnToEdit: { name: string, isSelected: boolean }[]
    columnsToSelect: { name: string, isSelected: boolean }[];
    data: { [key: string]: string }[];
  }[];
}

interface ILoadingFilesProps {
  files: IFile[];
  setFiles: Function;
  deleteFile: (index: number, files: IFile[]) => void;
}
const LoadingFiles: FC<ILoadingFilesProps> = ({
  files,
  setFiles,
  deleteFile,
}) => {
  const [counter, setCounter] = useState(1);

  const filesToRender = files;

  const handleFileButtonClick = (
    index: number,
    files: IFile[],
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    deleteFile(index, files);
    setCounter(counter + 1);
  };
  const { t } = useTranslation('outbound')
  return (
    <Cont>
      <Title>{t('campaigns.createCampaignForm.loadFiles.uploadContactDatabase')}</Title>

      <DropZone files={files} setFiles={setFiles} />

      <FilesButtonsCont>
        {filesToRender.map((file, index) => {
          return (
            <FileButton
              key={index}
              onClick={e => handleFileButtonClick(index, files, e)}
            >
              {file.fileName}
              <i className='bx bx-trash'></i>
            </FileButton>
          );
        })}
      </FilesButtonsCont>
    </Cont>
  );
};
const Cont = styled.div`
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${lightBackgroundBlue};
`;
const Title = styled.div`
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 20px;
`;
const FilesButtonsCont = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const FileButton = styled.button`
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid ${lightGreen};

  &:hover {
    color: red;
    border: 1px solid red;
    text-decoration: line-through;
  }

  & > i {
    line-height: 0px;
    font-size: 1.2rem;
    font-weight: 00;
    display: inline-block;
    margin-left: 5px;
    transform: translateY(3px);

  }
`;
export default LoadingFiles;
