import { IAgentToSelect } from 'components/outbound/atoms/selectableAgent';
import { IFile } from 'components/outbound/molecules/FormLoadingFiles';
import {
  ICampaignNumbers,
  ICreateCampaignFileContacts,
  ICreateCampaignMainInfoState,
  IExecutionDayButton,
} from 'library/interfaces/campaignInterfaces';
import {
  ICampaign,
  IPBXCampaignInfo,
  ITrunks,
} from 'store/outboundCampaigns/voiceCampaigns/reducer';

export const mainInfoService = (
  campaign: ICampaign,
  campaignIndexToUpdate: number | undefined,
): ICreateCampaignMainInfoState => {
  const executionDay: IExecutionDayButton[] = EXECUTION_DAYS().map(day => {
    const schedule = campaign.schedule.find(
      sche => sche.execution_day === day.execution_day,
    );
    if (schedule) {
      return { ...schedule, isSelected: true };
    }
    return day;
  });

  // if campaignIndexToUpdate is undefined, the campaign will be clone and add 'Copy - ' to name's beginning
  // if campaignIndexToUpdate is defined, the campaign will be edited and the name is the same
  const name =
    campaignIndexToUpdate === undefined
      ? 'Copy - ' + campaign.name
      : campaign.name;

  const newMainInfo: ICreateCampaignMainInfoState = {
    name: name,
    date_start: '', //campaign.date_start,
    date_end: '',//campaign.date_end,
    execution_day: executionDay,
    channels: campaign.channels.toString(),
    attempts: campaign.attempts.toString(),
    recording: campaign.recording,
    exclude_day: campaign.exclude_day,
  };
  return newMainInfo;
};

export const filesService = (campaign: ICampaign): IFile => {
  const newFile: IFile = {
    fileName: 'Copy from contacts',
    sheetsName: ['Copy_sheet'],
    sheets: [
      {
        name: 'Copy_sheet',
        columnsToSelect: [{ name: 'contact', isSelected: false }],
        //@ts-ignore
        data: campaign.contacts || [],
      },
    ],
  };
  return newFile;
};

// export const fileContactsService = (
//   campaign: ICampaign,
// ): ICreateCampaignFileContacts[] => {
//   const newContacts =
//     campaign.contacts &&
//     campaign.contacts.map(contact => {
//       return { contact: contact.contact, name: contact. e64_data: [] };
//     });
//   return newContacts || [];
// };

export const campaignNumbersService = (
  campaign: ICampaign,
): ICampaignNumbers => {
  return { callerId: campaign.callerid, did: campaign.did };
};

export const selectedAgentsService = (
  campaign: ICampaign,
  agentsToSelect: IAgentToSelect[],
): IAgentToSelect[] => {
  if (agentsToSelect.length === 0) return [];
  const newAgentsToSelect = agentsToSelect.map(agent => {
    const agentIsSelected = campaign.destination.agents.find(
      ag => ag.name === agent.exten,
    );
    if (agentIsSelected) {
      return { ...agent, isSelected: true };
    }
    return agent;
  });
  return newAgentsToSelect;
};

export const selectedTrunkIdService = (
  campaign: ICampaign,
  pbxCampaignInfo: IPBXCampaignInfo,
): ITrunks => {
  const trunk = pbxCampaignInfo.trunks.find(
    item => item.id === campaign.trunk_id,
  );
  if (!trunk) return { id: campaign.trunk_id, name: '' };
  return trunk;
};

export const EXECUTION_DAYS = (): IExecutionDayButton[] => [
  { isSelected: false, execution_day: 'mon', execution_time: [] },
  { isSelected: false, execution_day: 'tue', execution_time: [] },
  { isSelected: false, execution_day: 'wed', execution_time: [] },
  { isSelected: false, execution_day: 'thu', execution_time: [] },
  { isSelected: false, execution_day: 'fri', execution_time: [] },
  { isSelected: false, execution_day: 'sat', execution_time: [] },
  { isSelected: false, execution_day: 'sun', execution_time: [] },
];

export const groupContactsInArr = (
  contacts: any[],
  quantityOfEachGroup: number,
): any[][] => {
  const contactsGroupToSend: any[][] = [];

  for (let i = 0; i < contacts.length; i += quantityOfEachGroup) {
    const group = contacts.slice(i, i + quantityOfEachGroup);
    contactsGroupToSend.push(group);
  }

  return contactsGroupToSend;
};
