import {
  DELETE_PHONE_CALL_ID,
  DELETE_PHONE_CONTACT_ID,
  DELETE_PHONE_CONTACT_NAME,
  DELETE_PHONE_E64_DATA,
  DELETE_PHONE_HISTORY_CAMPAIGN_ID,
  DELETE_PHONE_HISTORY_DATA,
  PhoneTypes,
  UPDATE_BLIND_TRANSFER_EXT,
  UPDATE_BLIND_TRANSFER_EXT_LIST,
  UPDATE_PHONE_CALL_ID,
  UPDATE_PHONE_CONTACT_ID,
  UPDATE_PHONE_CONTACT_NAME,
  UPDATE_PHONE_DIALED_NUMBER,
  UPDATE_PHONE_E64_DATA,
  UPDATE_PHONE_EXTRA_BUTTON,
  UPDATE_PHONE_HISTORY_CAMPAIGN_ID,
  UPDATE_PHONE_HISTORY_DATA,
  UPDATE_PHONE_INCOMMING_MESSAGE,
  UPDATE_PHONE_IS_CALL_ANSWERED,
  UPDATE_PHONE_IS_CONECTED,
  UPDATE_PHONE_IS_HELD,
  UPDATE_PHONE_IS_INCOMMING_CALL,
  UPDATE_PHONE_IS_MUTED,
  UPDATE_PHONE_IS_REGISTERED,
  UPDATE_PHONE_IS_TO_MAKE_CALL,
  UPDATE_PHONE_IS_TRANSFER_CALL,
  UPDATE_PHONE_TIME_COUNTER,
} from './actionTypes';

export interface IPhoneMessage {
  from: string;
  message: string;
  read: boolean;
}
export interface IPhoneStore {
  // call is now is unique id
  uniqueId: string;
  e64_data: NonNullable<unknown>[];
  historyData: NonNullable<unknown>[];
  campaignId: string;
  contactId: string;
  contactName: string;
  dialedNumber: string;
  isConnected: boolean;
  isRegistered: boolean;
  isIncommingCall: boolean | undefined;
  isToMakeACall: boolean | undefined;
  isCallAnswered: boolean;
  timeCounter: string;
  isMuted: boolean;
  isHeld: boolean;
  isTransferCall: boolean | undefined;
  messages: IPhoneMessage[];
  blindTransferExt: string | undefined;
  blindTransferExtList: { exten: string; label: string }[] | undefined;
  extraButtonData: { exten: string; label: string } | undefined;
}

export const INIT_STATE: IPhoneStore = {
  // call is now is unique id
  uniqueId: '',
  e64_data: [],
  // esta propiedad se añade para el flujo de datos de las llamadas entrantes
  // para guardar la data del e64 en el historico de la agenda no se puede utilizar la propiedad e64_data
  // por eso se crea historyData, para que tenga su flujo.
  historyData: [],
  //esta propiedad almacena el campaign id para guardarlo en el histórico de llamadas
  campaignId: '',
  contactId: '',
  contactName: '',
  dialedNumber: '',
  isConnected: false,
  isRegistered: false,
  isIncommingCall: undefined,
  isCallAnswered: false,
  isToMakeACall: undefined,
  timeCounter: '00:00:00',
  isMuted: false,
  isHeld: false,
  isTransferCall: undefined,
  messages: [],
  blindTransferExt: undefined,
  blindTransferExtList: undefined,
  extraButtonData: undefined,
};

const phone = (state = INIT_STATE, action: PhoneTypes): IPhoneStore => {
  switch (action.type) {
    case UPDATE_PHONE_IS_CONECTED:
      return {
        ...state,
        isConnected: action.payload,
      };
    case UPDATE_PHONE_IS_REGISTERED:
      return {
        ...state,
        isRegistered: action.payload,
      };
    case UPDATE_PHONE_IS_INCOMMING_CALL:
      return {
        ...state,
        isIncommingCall: action.payload,
      };
    case UPDATE_PHONE_IS_CALL_ANSWERED:
      return {
        ...state,
        isCallAnswered: action.payload,
      };
    case UPDATE_PHONE_IS_TO_MAKE_CALL:
      return {
        ...state,
        isToMakeACall: action.payload,
      };
    case UPDATE_PHONE_DIALED_NUMBER:
      return {
        ...state,
        dialedNumber: action.payload,
      };
    case UPDATE_PHONE_TIME_COUNTER:
      return {
        ...state,
        timeCounter: action.payload,
      };
    case UPDATE_PHONE_IS_MUTED:
      return {
        ...state,
        isMuted: action.payload,
      };
    case UPDATE_PHONE_IS_HELD:
      return {
        ...state,
        isHeld: action.payload,
      };
    case UPDATE_PHONE_IS_TRANSFER_CALL:
      return {
        ...state,
        isTransferCall: action.payload,
      };
    case UPDATE_PHONE_INCOMMING_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case UPDATE_PHONE_CALL_ID: {
      return {
        ...state,
        uniqueId: action.payload,
      };
    }
    case UPDATE_PHONE_E64_DATA: {
      return {
        ...state,
        e64_data: action.payload,
      };
    }
    case DELETE_PHONE_E64_DATA: {
      return {
        ...state,
        e64_data: [],
      };
    }

    case UPDATE_PHONE_HISTORY_CAMPAIGN_ID: {
      return {
        ...state,
        campaignId: action.payload,
      };
    }
    case DELETE_PHONE_HISTORY_CAMPAIGN_ID: {
      return {
        ...state,
        campaignId: '',
      };
    }
    case UPDATE_PHONE_HISTORY_DATA: {
      return {
        ...state,
        historyData: action.payload,
      };
    }
    case DELETE_PHONE_HISTORY_DATA: {
      return {
        ...state,
        historyData: [],
      };
    }
    case DELETE_PHONE_CALL_ID: {
      return {
        ...state,
        uniqueId: '',
      };
    }
    case UPDATE_BLIND_TRANSFER_EXT: {
      return {
        ...state,
        blindTransferExt: action.payload,
      };
    }
    case UPDATE_BLIND_TRANSFER_EXT_LIST: {
      return {
        ...state,
        blindTransferExtList: action.payload,
      };
    }
    case UPDATE_PHONE_EXTRA_BUTTON: {
      return {
        ...state,
        extraButtonData: action.payload,
      };
    }
    case UPDATE_PHONE_CONTACT_ID : {
      return {
        ...state,
        contactId: action.payload
      }
    }
    case DELETE_PHONE_CONTACT_ID : {
      return {
        ...state,
        contactId: ''
      }
    }
    case UPDATE_PHONE_CONTACT_NAME : {
      return {
        ...state,
        contactName: action.payload
      }
    }
    case DELETE_PHONE_CONTACT_NAME : {
      return {
        ...state,
        contactName: ''
      }
    }
    default:
      return state;
  }
};

export default phone;
