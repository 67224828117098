import { IPhoneMessage } from './reducer';

export const UPDATE_PHONE_IS_CONECTED = 'UPDATE_PHONE_IS_CONECTED';
export const UPDATE_PHONE_IS_REGISTERED = 'UPDATE_PHONE_IS_REGISTERED';
export const UPDATE_PHONE_IS_INCOMMING_CALL = 'UPDATE_PHONE_IS_INCOMMING_CALL';
export const UPDATE_PHONE_IS_CALL_ANSWERED = 'UPDATE_PHONE_IS_CALL_ANSWERED';
export const UPDATE_PHONE_IS_TO_MAKE_CALL = 'UPDATE_PHONE_IS_TO_MAKE_CALL';
export const UPDATE_PHONE_DIALED_NUMBER = 'UPDATE_PHONE_DIALED_NUMBER';
export const UPDATE_PHONE_TIME_COUNTER = 'UPDATE_PHONE_TIME_COUNTER';
export const UPDATE_PHONE_IS_MUTED = 'UPDATE_PHONE_IS_MUTED';
export const UPDATE_PHONE_IS_HELD = 'UPDATE_PHONE_IS_HELD';
export const UPDATE_PHONE_IS_TRANSFER_CALL = 'UPDATE_PHONE_IS_TRANSFER_CALL';
export const UPDATE_PHONE_INCOMMING_MESSAGE = 'UPDATE_PHONE_INCOMMING_MESSAGE';
export const UPDATE_PHONE_CALL_ID = 'UPDATE_CALL_ID';
export const UPDATE_PHONE_E64_DATA = 'UPDATE_PHONE_E64_DATA';
export const DELETE_PHONE_E64_DATA = 'DELETE_PHONE_E64_DATA';
export const UPDATE_PHONE_HISTORY_CAMPAIGN_ID =
  'UPDATE_PHONE_HISTORY_CAMPAIGN_ID';
export const DELETE_PHONE_HISTORY_CAMPAIGN_ID =
  'DELETE_PHONE_HISTORY_CAMPAIGN_ID';
export const UPDATE_PHONE_HISTORY_DATA = 'UPDATE_PHONE_HISTORY_DATA';
export const DELETE_PHONE_HISTORY_DATA = 'DELETE_PHONE_HISTORY_DATA';
export const DELETE_PHONE_CALL_ID = 'DELETE_PHONE_CALL_ID';
export const UPDATE_BLIND_TRANSFER_EXT = 'UPDATE_BLIND_TRANSFER_EXT';
export const UPDATE_BLIND_TRANSFER_EXT_LIST = 'UPDATE_BLIND_TRANSFER_EXT_LIST';
export const UPDATE_PHONE_EXTRA_BUTTON = 'UPDATE_PHONE_EXTRA_BUTTON';
export const UPDATE_PHONE_CONTACT_ID = 'UPDATE_PHONE_CONTACT_ID';
export const DELETE_PHONE_CONTACT_ID = 'DELETE_PHONE_CONTACT_ID';
export const UPDATE_PHONE_CONTACT_NAME = 'UPDATE_PHONE_CONTACT_NAME';
export const DELETE_PHONE_CONTACT_NAME = 'DELETE_PHONE_CONTACT_NAME';

export type PhoneTypes =
  | IUpdatePhoneIsConnected
  | IUpdatePhoneIsRegistered
  | IUpdatePhoneIsIncommingCall
  | IUpdatePhoneIsCallAnswered
  | IUpdatePhoneIsToMakeCall
  | IUpdatePhoneDialedNumber
  | IUpdatePhoneTimeCounter
  | IUpdatePhoneIsMuted
  | IUpdatePhoneIsHeld
  | IUpdatePhoneIsTransferCall
  | IUpdatePhoneIncommingMessage
  | IUpdatePhoneCallId
  | IUpdatePhoneE64Data
  | IDeletePhoneE64Data
  | IUpdatePhoneHistoryCampaignId
  | IDeletePhoneHistoryCampaignId
  | IUpdatePhoneHistoryData
  | IDeletePhoneHistoryData
  | IDeletePhoneCallId
  | IUpdateBlindTransferExt
  | IUpdateBlindTransferExtList
  | IUpdatePhoneExtraButton
  | IUpdatePhoneContactID
  | IDeletePhoneContactID
  | IUpdatePhoneContactName
  | IDeletePhoneContactName;

export interface IUpdatePhoneIsConnected {
  type: typeof UPDATE_PHONE_IS_CONECTED;
  payload: boolean;
}

export interface IUpdatePhoneIsRegistered {
  type: typeof UPDATE_PHONE_IS_REGISTERED;
  payload: boolean;
}

export interface IUpdatePhoneIsIncommingCall {
  type: typeof UPDATE_PHONE_IS_INCOMMING_CALL;
  payload: boolean | undefined;
}

export interface IUpdatePhoneIsCallAnswered {
  type: typeof UPDATE_PHONE_IS_CALL_ANSWERED;
  payload: boolean;
}

export interface IUpdatePhoneIsToMakeCall {
  type: typeof UPDATE_PHONE_IS_TO_MAKE_CALL;
  payload: boolean | undefined;
}

export interface IUpdatePhoneDialedNumber {
  type: typeof UPDATE_PHONE_DIALED_NUMBER;
  payload: string;
}

export interface IUpdatePhoneTimeCounter {
  type: typeof UPDATE_PHONE_TIME_COUNTER;
  payload: string;
}

export interface IUpdatePhoneIsMuted {
  type: typeof UPDATE_PHONE_IS_MUTED;
  payload: boolean;
}

export interface IUpdatePhoneIsHeld {
  type: typeof UPDATE_PHONE_IS_HELD;
  payload: boolean;
}

export interface IUpdatePhoneIsTransferCall {
  type: typeof UPDATE_PHONE_IS_TRANSFER_CALL;
  payload: boolean | undefined;
}

export interface IUpdatePhoneIncommingMessage {
  type: typeof UPDATE_PHONE_INCOMMING_MESSAGE;
  payload: IPhoneMessage;
}

export interface IUpdatePhoneCallId {
  type: typeof UPDATE_PHONE_CALL_ID;
  payload: string;
}

export interface IUpdatePhoneE64Data {
  type: typeof UPDATE_PHONE_E64_DATA;
  payload: NonNullable<unknown>[];
}

export interface IDeletePhoneE64Data {
  type: typeof DELETE_PHONE_E64_DATA;
}

export interface IUpdatePhoneHistoryCampaignId {
  type: typeof UPDATE_PHONE_HISTORY_CAMPAIGN_ID;
  payload: string;
}

export interface IDeletePhoneHistoryCampaignId {
  type: typeof DELETE_PHONE_HISTORY_CAMPAIGN_ID;
}

export interface IUpdatePhoneHistoryData {
  type: typeof UPDATE_PHONE_HISTORY_DATA;
  payload: NonNullable<unknown>[];
}

export interface IDeletePhoneHistoryData {
  type: typeof DELETE_PHONE_HISTORY_DATA;
}

export interface IDeletePhoneCallId {
  type: typeof DELETE_PHONE_CALL_ID;
}

export interface IUpdateBlindTransferExt {
  type: typeof UPDATE_BLIND_TRANSFER_EXT;
  payload: string | undefined;
}

export interface IUpdateBlindTransferExtList {
  type: typeof UPDATE_BLIND_TRANSFER_EXT_LIST;
  payload: { exten: string; label: string }[] | undefined;
}

export interface IUpdatePhoneExtraButton {
  type: typeof UPDATE_PHONE_EXTRA_BUTTON;
  payload: { exten: string; label: string } | undefined;
}

export interface IUpdatePhoneContactID {
  type: typeof UPDATE_PHONE_CONTACT_ID;
  payload: string;
}

export interface IDeletePhoneContactID {
  type: typeof DELETE_PHONE_CONTACT_ID;
}

export interface IUpdatePhoneContactName {
  type: typeof UPDATE_PHONE_CONTACT_NAME;
  payload: string;
}

export interface IDeletePhoneContactName {
  type: typeof DELETE_PHONE_CONTACT_NAME;
}
