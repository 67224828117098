import { IError } from 'store/reports/reducer';
import {
  DELETE_LIVE_CONVERSATION_SESSION_FAIL,
  DELETE_LIVE_CONVERSATION_SESSION_SUCCESS,
  GET_AGENTS_CONNECTED_TO_WA_FAIL,
  GET_AGENTS_CONNECTED_TO_WA_SUCCESS,
  GET_ALL_SESSIONS_FROM_MONITOR,
  GET_ALL_SESSIONS_FROM_MONITOR_FAIL,
  GET_ALL_SESSIONS_FROM_MONITOR_SUCCESS,
  GET_LIVE_CONVERSATION_SESSION_LOGS,
  GET_LIVE_CONVERSATION_SESSION_LOGS_FAIL,
  GET_LIVE_CONVERSATION_SESSION_LOGS_SUCCESS,
  GET_LIVE_CONVERSATION_SESSION_MESSAGES,
  GET_LIVE_CONVERSATION_SESSION_MESSAGES_FAIL,
  GET_LIVE_CONVERSATION_SESSION_MESSAGES_SUCCESS,
  GET_SESSIONS_FROM_MONITOR,
  GET_SESSIONS_FROM_MONITOR_FAIL,
  GET_SESSIONS_FROM_MONITOR_SUCCESS,
  LiveConversationsActionTypes,
  PUT_LIVE_CONVERSATION_TRANSFER_SESSION,
  PUT_LIVE_CONVERSATION_TRANSFER_SESSION_FAIL,
  PUT_LIVE_CONVERSATION_TRANSFER_SESSION_SUCCESS,
  PUT_RESTORE_WA_SESSION_FAIL,
  PUT_RESTORE_WA_SESSION_SUCCESS,
} from './actionTypes';

export interface ILiveConversationSession {
  created_at: string;
  active_source: string;
  campaign_id: string;
  update: string;
  source_name: string;
  assigned_to: string;
  updated_at: string;
  active_assigned_to: string;
  active?: boolean;
  source_id: string;
  channel_id: string;
  id: string;
  type: string;
  is_expired: boolean;

  // para usar en la aplicación, no viene de servidor
  isLoading?: boolean;
}
export interface ILiveConversationSessionLogs {
  userAgent: string;
  ipAddress: string;
  action: string;
  field_before: string;
  field_after: string;
  timestamp: string;
  user: string;
  field: string;
  description: string;
  id: string;
}

export interface ILiveConversationActiveAgents {
  id: string;
  name: string;
  picture: string;
  timestamp: string;
}
export interface ILiveConversationAgents {
  total: number;
  totalConnected: number;
  totalUnconnected: number;
  activeAgents: ILiveConversationActiveAgents[];
}
export interface ILiveConversationsCampaign {
  dispatched_error: number;
  dispatched_failed: number;
  dispatched_sent: number;
  sqs_uri: string;
  dispatched_read: number;
  dispatched_delivered: number;
  dispatched_enqueued: number;
  status: string;
  timestamp: string;
  dispatched_replied: number;
  source: string;
  contacts_count: number;
  name: string;
  dispatched_count: number;
  app_id: string;
  updated_at: string;
  active: true;
  template_id: string;
  starting_response: string;
  owner: string;
  id: string;
  color: string;
  scenario_id: string;
  totalUnreadConversations: number;
  totalReadConversations: number;
  totalAssignedConversations: number;
  totalUnassignedConversations: number;
}
export interface ILiveConversationsStore {
  sessions: {
    lastKey: string | null;
    isLoading: boolean;
    error: IError | null;
    sessions: ILiveConversationSession[];
  };
  sessionMessages: {
    isLoading: boolean;
    error: IError | null;
    messages: { [key: string]: string | number }[];
  };
  sessionLogs: {
    isLoading: boolean;
    error: IError | null;
    logs: ILiveConversationSessionLogs[];
  };
  agents: ILiveConversationAgents;
  campaign?: ILiveConversationsCampaign;
}

const INIT_STATE: ILiveConversationsStore = {
  sessions: {
    lastKey: null,
    isLoading: false,
    error: null,
    sessions: [],
  },
  sessionMessages: {
    isLoading: false,
    error: null,
    messages: [],
  },
  sessionLogs: {
    isLoading: false,
    error: null,
    logs: [],
  },
  agents: {
    total: 0,
    totalConnected: 0,
    totalUnconnected: 0,
    activeAgents: [],
  },
};

const liveConversations = (
  state = INIT_STATE,
  action: LiveConversationsActionTypes,
): ILiveConversationsStore => {
  switch (action.type) {
    case GET_LIVE_CONVERSATION_SESSION_MESSAGES: {
      return {
        ...state,
        sessionMessages: {
          ...state.sessionMessages,
          isLoading: true,
          error: null,
          messages: [],
        },
      };
    }
    case GET_LIVE_CONVERSATION_SESSION_MESSAGES_SUCCESS: {
      return {
        ...state,
        sessionMessages: {
          ...state.sessionMessages,
          isLoading: false,
          messages: [...action.payload.message],
        },
      };
    }
    case GET_LIVE_CONVERSATION_SESSION_MESSAGES_FAIL: {
      return {
        ...state,
        sessionMessages: {
          ...state.sessionMessages,
          isLoading: false,
          error: action.paylaod.error,
        },
      };
    }

    case GET_LIVE_CONVERSATION_SESSION_LOGS: {
      return {
        ...state,
        sessionLogs: {
          ...state.sessionLogs,
          isLoading: true,
          error: null,
          logs: [],
        },
      };
    }
    case GET_LIVE_CONVERSATION_SESSION_LOGS_SUCCESS: {
      return {
        ...state,
        sessionLogs: {
          ...state.sessionLogs,
          isLoading: false,
          error: null,
          logs: [...action.payload.logs],
        },
      };
    }
    case GET_LIVE_CONVERSATION_SESSION_LOGS_FAIL: {
      return {
        ...state,
        sessionLogs: {
          ...state.sessionLogs,
          isLoading: false,
          error: action.paylaod.error,
        },
      };
    }

    case PUT_LIVE_CONVERSATION_TRANSFER_SESSION: {
      const { sessionId } = action.payload;
      const newSessions = structuredClone(state.sessions.sessions);

      const transferSessionIndex = newSessions.findIndex(
        sess => sess.id === sessionId,
      );

      if (transferSessionIndex !== -1) {
        newSessions[transferSessionIndex].isLoading = true;
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          sessions: newSessions,
        },
      };
    }
    case PUT_LIVE_CONVERSATION_TRANSFER_SESSION_SUCCESS: {
      const { session } = action.payload;
      const newSessions = structuredClone(state.sessions.sessions);

      const transferSessionIndex = newSessions.findIndex(
        sess => sess.id === session.id,
      );
      if (transferSessionIndex !== -1) {
        newSessions[transferSessionIndex] = session;
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          sessions: newSessions,
        },
      };
    }
    case PUT_LIVE_CONVERSATION_TRANSFER_SESSION_FAIL: {
      const { error, sessionId } = action.payload;

      const newSessions = structuredClone(state.sessions.sessions);

      const transferSessionIndex = newSessions.findIndex(
        sess => sess.id === sessionId,
      );

      if (transferSessionIndex !== -1) {
        newSessions[transferSessionIndex].isLoading = false;
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          error: error,
          sessions: newSessions,
        },
      };
    }
    case GET_SESSIONS_FROM_MONITOR: 
    case GET_ALL_SESSIONS_FROM_MONITOR: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoading: true,
          error: null,
        },
      };
    }
    case GET_SESSIONS_FROM_MONITOR_SUCCESS: 
    case GET_ALL_SESSIONS_FROM_MONITOR_SUCCESS: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoading: false,
          sessions: action.payload.sessions,
          lastKey: action.payload.lastKey,
        },
        campaign: action.payload.campaign,
      };
    }
    case GET_SESSIONS_FROM_MONITOR_FAIL: 
    case GET_ALL_SESSIONS_FROM_MONITOR_FAIL: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }

    case DELETE_LIVE_CONVERSATION_SESSION_SUCCESS: {
      const { sessionId } = action.payload;

      const newSessions = structuredClone(state.sessions.sessions);

      const transferSessionIndex = newSessions.findIndex(
        sess => sess.id === sessionId,
      );

      if (transferSessionIndex !== -1) {
        newSessions[transferSessionIndex].active = false;
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          sessions: newSessions,
        },
      };
    }
    case DELETE_LIVE_CONVERSATION_SESSION_FAIL: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          error: action.payload.error,
        },
      };
    }

    case GET_AGENTS_CONNECTED_TO_WA_SUCCESS: {
      return {
        ...state,
        agents: action.payload.agents,
      };
    }
    case GET_AGENTS_CONNECTED_TO_WA_FAIL: {
      return {
        ...state,
        agents: INIT_STATE.agents,
      };
    }

    case PUT_RESTORE_WA_SESSION_SUCCESS: {
      const { sessionId } = action.payload;
      const newSessions = structuredClone(state.sessions.sessions);

      const restoreSessionIndex = newSessions.findIndex(
        sess => sess.id === sessionId,
      );

      if (restoreSessionIndex !== -1) {
        newSessions[restoreSessionIndex].active = true;
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          sessions: newSessions,
        },
      };
    }
    case PUT_RESTORE_WA_SESSION_FAIL: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          error: action.payload.error,
        },
      };
    }

    default:
      return state;
  }
};

export default liveConversations;
