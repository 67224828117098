import {
  danger,
  darkBorder,
  darkGrayBg,
  lightBackground,
  lightBackgroundBlue,
  lightGreen,
  primary,
} from 'library/colors';
import styled from 'styled-components';
import { IScheduleCall } from '../organisms/phoneBook';
import React, { useState } from 'react';
import { IUser } from 'library/interfaces/crdInterfaces';

interface IScheduleCallProps {
  call: IScheduleCall;
  users: IUser[];
  e64Data: { [key: string]: string }[];
  handleEditButtonClick: (call: IScheduleCall) => void;
  deleteScheduleCall: (campaignId: string, callId: string) => void;
}
const ScheduleCall = ({
  call,
  e64Data,
  users,
  handleEditButtonClick,
  deleteScheduleCall,
}: IScheduleCallProps) => {
  const [showNotes, setShowNotes] = useState(false);

  const userName = users.find(usr => usr.exten === call.agent)?.name || '';

  const stateColors = {
    'Llamada agendada': darkBorder,
    Contactado: primary,
    'Encolada': 'orange',
    'No se pudo contactar al cliente': danger,
  };
  return (
    <ScheduleCont>
      <Name stateColor={call.state ? stateColors[call.state] : ''}>
        {userName}
      </Name>
      <div>{call.contact}</div>
      <div>{call.contact_datetime}</div>
      <ShowInfoButton danger={false} onClick={() => setShowNotes(true)}>
        <i className="bx bxs-show"></i>
        <NotesHoverCont>
          {e64Data.map((item, index) => {
            const key = Object.keys(item)[0] || '';
            return (
              <div key={index}>
                <NoteTitle>{key}</NoteTitle>
                <NoteInfo>{item[key]}</NoteInfo>
              </div>
            );
          })}
        </NotesHoverCont>
      </ShowInfoButton>
      <CloseButton danger={false} onClick={() => handleEditButtonClick(call)}>
        <i className="bx bx-pencil"></i>
      </CloseButton>
      <CloseButton
        danger={true}
        onClick={() => deleteScheduleCall(call.campaign_id, call.id)}
      >
        <i className="bx bx-trash"></i>
      </CloseButton>
      {showNotes && (
        <NotesBackCont>
          <NotesCont>
            <Exit onClick={() => setShowNotes(false)}>X</Exit>
            <NotesTitle>Notas para la llamada</NotesTitle>
            <NotesContent>
              {e64Data.map((item, index) => {
                const key = Object.keys(item)[0];
                return (
                  <div key={index}>
                    <NoteTitle>{key}:</NoteTitle>
                    <NoteInfo>{item[key]}</NoteInfo>
                  </div>
                );
              })}
            </NotesContent>
            <ExtraInfoCont>
              <ExtraInfoTitle>Campaña</ExtraInfoTitle>
              {e64Data.map((item, index) =>
                item['Campaign'] ? (
                  <ExtraInfoData key={index}>{item['Campaign']}</ExtraInfoData>
                ) : null,
              )}
              <ExtraInfoTitle>Estado</ExtraInfoTitle>
              <ExtraInfoData>{call.state}</ExtraInfoData>
              <ExtraInfoTitle>Intentos</ExtraInfoTitle>
              <ExtraInfoData>{call.attempts}</ExtraInfoData>
              <ExtraInfoTitle>Agendado para el día</ExtraInfoTitle>
              <ExtraInfoData>{call.contact_datetime}</ExtraInfoData>
              <ExtraInfoTitle>Contactado el día</ExtraInfoTitle>
              <ExtraInfoData>
                {call.call_datetime || 'Pendiente por contactar'}
              </ExtraInfoData>
            </ExtraInfoCont>
          </NotesCont>
        </NotesBackCont>
      )}
    </ScheduleCont>
  );
};

const ScheduleCont = styled.div`
  margin-bottom: 10px;
  padding: 10px 15px 10px 10px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 2fr 0.7fr 1fr 50px 50px 40px;
  align-items: center;
  background-color: ${lightBackground};
`;
const Name = styled.div<IName>`
  padding-left: 2px;
  border-left: 4px solid;
  border-color: ${props => props.stateColor};
`;
interface IName {
  stateColor: string;
}
const CloseButton = styled.div<ICloseButton>`
  color: #fff;
  text-align: center;
  flex-grow: 1;
  width: 40px;
  font-size: 1.2rem;
  line-height: 0;
  padding: 6px 0;
  border-radius: 50px;
  cursor: pointer;
  background-color: ${darkBorder};

  &:hover {
    background-color: ${props => (props.danger ? danger : primary)};
  }
`;
interface ICloseButton {
  danger: boolean;
}

const ShowInfoButton = styled(CloseButton)`
  position: relative;

  &:hover > div {
    display: block;
  }
`;

const NotesHoverCont = styled.div`
  display: none;
  width: 430px;
  height: 100px;
  position: absolute;
  left: -430px;
  top: 50%;
  transform: translateY(-20%);
  background-color: #fff;
  box-shadow: 0 0 10px ${darkBorder};
  border-radius: 5px;
  z-index: 100;
  padding: 10px 20px;

  line-height: 12px;
  text-align: left;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;

const NoteTitle = styled.div`
  color: ${lightGreen};
  margin-bottom: 5px;
  font-size: 0.8rem;
`;
const NoteInfo = styled.div`
  color: ${darkGrayBg};
  margin-bottom: 10px;
  font-size: 0.8rem;
`;
const NotesBackCont = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
  display: grid;
  place-content: center;
`;
const NotesCont = styled.div`
  width: 600px;
  height: 400px;
  padding: 10px 20px 20px;
  background-color: ${lightBackground};
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(123, 153, 4, 0.5);
  position: relative;
  z-index: 1000;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
`;
const NotesTitle = styled.div`
  text-align: center;
  font-size: 1rem;
  grid-column: 1 / -1;
`;

const NotesContent = styled.div`
  padding-top: 10px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${danger};
  }
`;
const ExtraInfoCont = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
`;
const ExtraInfoTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 300;
`;
const ExtraInfoData = styled.div`
  margin-bottom: 15px;
  font-size: 0.8rem;
  font-weight: 500;
`;
export default ScheduleCall;
