import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { lightBackground, lightText, primary, secondary } from 'library/colors';

interface ISpyButton {
  onOutsideClick: () => void;
  handleButtonClick: (option: string) => void;
}
export const SpyButtons = ({
  onOutsideClick,
  handleButtonClick,
}: ISpyButton) => {
  const contRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contRef.current && !contRef.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <SpyCont ref={contRef}>
      <Button onClick={() => handleButtonClick('listen')}>Solo escucha</Button>
      <Button onClick={() => handleButtonClick('whisper')}>
        Hablar al agente
      </Button>
      <Button onClick={() => handleButtonClick('barge')}>
        Hablar con ambos
      </Button>
    </SpyCont>
  );
};

const SpyCont = styled.div`
  position: absolute;
  top: -5px;
  left: 100%;
  height: 50px;
  padding: 0 20px;
  color: black;
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: ${lightBackground};

  &:hover {
    cursor: auto;
  }
`;
const Button = styled.div`
  padding: 10px 20px;
  border-radius: 5px;
  flex-grow: 1;
  background-color: ${secondary};
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    color: ${lightText};
    background-color: ${primary};
  }
`;

interface ISpyButton {}
