import {
  danger,
  darkGrayBg,
  lightBackground,
  lightBackgroundBlue,
  lightGreen,
  primary,
} from 'library/colors';
import {
  TIndexedDbCallData,
  updateDataToIndexedDb,
} from 'library/indexedDb/indexedDB';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { put } from 'helpers/api_helper';
import styled from 'styled-components';

interface ITagsAndEditFormProps {
  exit: () => void;
  call: TIndexedDbCallData;
}
const TagsAndEditForm = ({ call, exit }: ITagsAndEditFormProps) => {
  const [tag, setTag] = useState('');
  const [info, setInfo] = useState('');
  const [newContactName, setNewContactName] = useState('');
  const [notes, setNotes] = useState('');

  function encodeToBase64Unicode(str: string): string {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
      }),
    );
  }

  const handleSaveTag = async () => {
    try {
      const etiqueta: { [key: string]: string | {} | undefined } = {
        contact_id: call?.contactId || call.number,
        uniqueid: call?.uniqueId || '',
        label: tag || '',
        info: info || '',
        notes: notes || '',
        e64_data: encodeToBase64Unicode(JSON.stringify(call?.e64_data || '')) || '',
      };

      if (newContactName) etiqueta.contact_name = newContactName;

      if(call.direction === 'outgoing') etiqueta.campaign_id = call.campaignId

        await put('/pbx/campaign/register/call/notes', etiqueta)

      const callUpdated: TIndexedDbCallData = {
        ...call,
        tag: tag,
        info: info,
        newContacName: newContactName,
        notes: notes,
      };

      updateDataToIndexedDb(callUpdated);
      toast.success('Etiqueta guardada exitosamente');
    } catch (error) {
      console.log(error);
      toast.error('Hubo un error etiquetando la llamada');
    }
  };

  useEffect(() => {
    setTag(call.tag || '');
    setInfo(call.info || '');
    setNewContactName(call.newContacName || '');
    setNotes(call.notes || '');
  }, [call]);

  return (
    <PopUpContainer>
      <Cont>
        <FormCont>
          <InputCont>
            <Label>Etiquetas de llamada</Label>
            <Select value={tag} onChange={e => setTag(e.target.value)}>
              <option value="">Selecciona una etiqueta</option>
              <option value="Dato erróneo">Dato erróneo</option>
              <option value="Venta">Venta</option>
              <option value="No le interesa">No le interesa</option>
              <option value="Buzón de voz inicial">Buzón de voz inicial</option>
              <option value="No contesta">No contesta</option>
              <option value="Cliente colgó">Cliente colgó</option>
            </Select>
          </InputCont>
          <InputCont>
            <Label>Observaciones</Label>
            <Textarea value={info} onChange={e => setInfo(e.target.value)} />
          </InputCont>

          <InputCont>
            <Label>Nombre actual</Label>
            <Input disabled value={call.contactName} />
          </InputCont>
          <InputCont>
            <Label>Nuevo nombre</Label>
            <Input
              value={newContactName}
              onChange={e => setNewContactName(e.target.value)}
            />
          </InputCont>
          <InputCont>
            <Label>Notas de la edición de nombre</Label>
            <Textarea value={notes} onChange={e => setNotes(e.target.value)} />
          </InputCont>
          <ButtonsCont>
            <Button danger onClick={() => exit()}>
              Cancelar
            </Button>
            <Button onClick={() => handleSaveTag()}>Guardar</Button>
          </ButtonsCont>
        </FormCont>
        <E64Cont>
          {call.e64_data?.map((item, index) => {
            const key = Object.keys(item)[0];
            return (
              <div key={index}>
                <NoteTitle>{key}:</NoteTitle>
                <NoteInfo>{item[key]}</NoteInfo>
              </div>
            );
          })}
        </E64Cont>
      </Cont>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1000;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
`;
const Cont = styled.div`
  display: grid;
  grid-template-columns: 340px 1fr;
  grid-gap: 10px;
  height: 98%;
  /* border: 1px solid; */
`;
const FormCont = styled.div`
  /* position: absolute; */
  background-color: 10px;
  padding: 10px;
  /* border: 1px solid; */
`;
const InputCont = styled.div`
  /* border: 1px solid; */
  margin-bottom: 20px;
`;
const Label = styled.label`
  margin: 0;
  padding: 0;
  /* line-height: 0; */
  font-size: 0.7rem;
  /* font-weight: 500; */
  color: ${darkGrayBg};
`;
const Select = styled.select`
  width: 100%;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid ${primary};
  background-color: #fff;
`;
const Textarea = styled.textarea`
  display: block;
  width: 100%;
  height: 100px;
  resize: none;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid ${primary};
`;
const Input = styled.input`
  display: block;
  width: 100%;
  height: 29px;
  resize: none;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid ${primary};
`;

const ButtonsCont = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
const Button = styled.div<IButton>`
  padding: 5px 20px;
  font-size: 0.9rem;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  background-color: ${props => (props.danger ? danger : primary)};
`;
interface IButton {
  danger?: boolean;
}

const E64Cont = styled.div`
  padding: 10px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: ${lightBackground};
  border-radius: 10px;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const NoteTitle = styled.div`
  color: ${lightGreen};
  margin-bottom: 5px;
  font-size: 0.8rem;
`;
const NoteInfo = styled.div`
  color: ${darkGrayBg};
  margin-bottom: 10px;
  font-size: 0.8rem;
`;
export default TagsAndEditForm;
