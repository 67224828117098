import DropZone from 'components/outbound/atoms/dropzone';
import { IFile } from 'components/outbound/molecules/FormLoadingFiles';
import { lightBackgroundBlue, lightGreen, primary } from 'library/colors';
import ASESP_JOSE_I_FAVORITE_TEMPLATES from 'library/enableSpecificFeatures/joseIgnacioTemplatesASESP';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { IChannel } from 'library/interfaces/channelsInterfaces';
import { IScenario } from 'library/interfaces/scenariosInterfaces';
import IStore from 'library/interfaces/storeInterface';
import { IGupshupAppTemplate } from 'library/interfaces/whatsappCampaignInterfaces';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChannelsAction,
  getGupshupAppTemplatesAction,
  getScenarios,
} from 'store/actions';
import { IChannelStore } from 'store/channels/reducer';
import { IWACampaignStore } from 'store/outboundCampaigns/waCampaigns/reducer';
import styled from 'styled-components';

export interface IFirstStageData {
  campaignName: string;
  startingResponse: string;
  files: IFile[];
  channel: IChannel | null;
  scenario: IScenario | null;
  template: IGupshupAppTemplate | null;
  fileUrl: string | null;
  fileName: string | null;
}
interface ICreateWaCampaignFirstStageProps {
  isManualKey: boolean;
  setIsManualKey: (show: boolean) => void;
  firstStageData: IFirstStageData;
  setFirstStageData: (data: React.SetStateAction<IFirstStageData>) => void;
}
const CreateWaCampaignFirstStage: FC<ICreateWaCampaignFirstStageProps> = ({
  isManualKey,
  setIsManualKey,
  firstStageData,
  setFirstStageData,
}) => {
  const { t } = useTranslation('outbound');
  const dispatch = useDispatch();
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const scenarios = useSelector<IStore, IScenario[]>(
    store => store.scenarios.scenarios,
  );
  const { isLoading, channels } = useSelector<IStore, IChannelStore>(
    store => store.channels,
  );
  const { templates } = useSelector<IStore, IWACampaignStore>(
    store => store.waCampaigns,
  );
  const files = useAppSelector(store => store.files);

  const [isManualUrl, setIsManualUrl] = useState(false);

  const filesToRender = useMemo(() => {
    if (!firstStageData.template || files.files.length === 0) return [];
    if (firstStageData.template.templateType === 'DOCUMENT') {
      return files.files.filter(file => file.filename.endsWith('.pdf'));
    } else if (firstStageData.template.templateType === 'IMAGE') {
      return files.files.filter(file => file.filename.endsWith('.jpg'));
    } else if (firstStageData.template.templateType === 'VIDEO') {
      return files.files.filter(file => file.filename.endsWith('.mp4'));
    }
    return [];
  }, [files.files, firstStageData.template]);

  const scenariosToRender = useMemo(() => {
    return scenarios.filter(scenario => scenario.name.includes(owner));
  }, [scenarios, owner]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstStageData(prev => ({
      ...prev,
      campaignName: event.target.value,
    }));
  };

  const handleStartingResponseChange = (value: string) => {
    setFirstStageData(prev => ({
      ...prev,
      startingResponse: value,
    }));
  };

  const handleAddFile = (files: IFile[]) => {
    setFirstStageData(prev => ({
      ...prev,
      files: [...files],
    }));
  };

  const handleDeleteFile = (
    fileIndex: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const currentFiles = [...firstStageData.files];
    currentFiles.splice(fileIndex, 1);
    setFirstStageData(prev => ({
      ...prev,
      files: [...currentFiles],
    }));
  };

  const handleSelectChannel = (channelId: string) => {
    if (channelId === '') return;
    const channel = channels.find(chan => chan.id === channelId);
    setFirstStageData(prev => ({ ...prev, channel: channel || null }));

    if (!channel) return;
    dispatch(getGupshupAppTemplatesAction(channel.appId));
  };

  const handleSelectTemplate = (templateId: string) => {
    const template = templates.templates.find(temp => temp.id === templateId);

    if (!template) return;

    const regEx = /\|\s\[(.*?)\]/g;
    let match;
    const matches = [];
    let startingResponse = '';

    while ((match = regEx.exec(template.data)) !== null) {
      matches.push(match[1]);
    }

    matches.forEach((button, index) => {
      if (index + 1 !== matches.length) {
        return (startingResponse = startingResponse + button + '|');
      }
      startingResponse = startingResponse + button + '';
    });

    setFirstStageData(prev => ({
      ...prev,
      template: template,
      startingResponse,
    }));
  };

  const handleSelectFile = (fileUrl: string) => {
    setFirstStageData(prev => ({
      ...prev,
      fileUrl,
    }));
  };

  const handleFileNameInputChange = (value: string) => {
    setFirstStageData(prev => ({
      ...prev,
      fileName: value,
    }));
  };

  const handleSelectScenario = (scenarioId: string) => {
    const scenario = scenarios.find(scen => scen.id === scenarioId);

    setFirstStageData(prev => ({
      ...prev,
      scenario: scenario || null,
    }));
  };

  useEffect(() => {
    if (channels.length > 0) return;
    dispatch(getChannelsAction());
    dispatch(getScenarios());
  }, []);

  return (
    <Cont>
      <LeftBlock>
        <InputGroup>
          <label>{t('SMScampaigns.SMSCreateCampaignForm.campaignName')}</label>
          <input
            type="text"
            name="name"
            value={firstStageData.campaignName}
            onChange={e => handleNameChange(e)}
          />
        </InputGroup>
        <DropZone files={firstStageData.files} setFiles={handleAddFile} />

        <FilesButtonsCont>
          {firstStageData.files.map((file, index) => {
            return (
              <FileButton key={index} onClick={e => handleDeleteFile(index, e)}>
                {file.fileName}
                <i className="bx bx-trash"></i>
              </FileButton>
            );
          })}
        </FilesButtonsCont>
      </LeftBlock>
      <RightBlock>
        <Label margin="10px 0 0">Cuentas de WhatsApp</Label>
        <div>
          <AppSelect
            name="channelSelect"
            onChange={e => handleSelectChannel(e.target.value)}
          >
            <option value="">
              {isLoading ? 'Cargando Canales' : 'Seleccionar Canal'}
            </option>
            {channels.map((chan, index) => {
              if (chan.source !== 'whatsapp') return;
              return (
                <option key={index} value={chan.id}>
                  {chan.name}
                </option>
              );
            })}
          </AppSelect>
        </div>
        <div>
          <Label margin="0px 0 0">Platillas de Mensaje</Label>

          <TemplateSelect
            name="templateSelect"
            onChange={e => handleSelectTemplate(e.target.value)}
          >
            <option value="">
              {templates.isLoading
                ? 'Cargando Plantillas'
                : 'Seleccionar Plantilla'}
            </option>

            {owner === 'ASESP' &&
              firstStageData.channel?.id ===
                'asesp_014994169be53141adb678ebbb0e3025fd' && (
                <optgroup label="Favoritas">
                  {ASESP_JOSE_I_FAVORITE_TEMPLATES.map((template, index) => {
                    return (
                      <option key={index} value={template.templateId}>
                        &#9733;&nbsp;&nbsp;{template.templateName}
                      </option>
                    );
                  })}
                </optgroup>
              )}

            {templates.templates.map((template, index) => {
              return (
                <option key={index} value={template.id}>
                  {template.elementName}
                </option>
              );
            })}
          </TemplateSelect>
        </div>
        <SelectFileCont>
          {firstStageData.template?.templateType === 'TEXT' && (
            <MessageCont>
              {firstStageData.template && firstStageData.template.data}
            </MessageCont>
          )}

          {firstStageData.template?.templateType &&
            firstStageData.template?.templateType !== 'TEXT' && (
              <>
                <div>
                  <FlexCont>
                    <Label margin="0px 0 0">{isManualUrl ? 'Ingresa la url del archivo' : 'Selecciona el archivo'}</Label>
                    <Label margin="0px 0 0">
                      <input
                        type="checkbox"
                        checked={isManualUrl}
                        onChange={() => setIsManualUrl(!isManualUrl)}
                      />
                      Manual
                    </Label>
                  </FlexCont>

                  {isManualUrl && <InputKey>
                    <input
                      type="text"
                      placeholder="Url"
                      value={firstStageData.fileUrl || ''}
                      onChange={e => handleSelectFile(e.target.value)}
                    />
                  </InputKey>}

                  {!isManualUrl && <TemplateSelect
                  name="fileSelect"
                  onChange={e => handleSelectFile(e.target.value)}
                >
                  <option value="">
                    {files.isLoading
                      ? 'Cargando Archivos'
                      : 'Seleccionar Archivo'}
                  </option>
                  {filesToRender.map((file, index) => {
                    return (
                      <option key={index} value={file.url}>
                        {file.filename}
                      </option>
                    );
                  })}
                </TemplateSelect>}
                </div>
                {firstStageData.template?.templateType === 'DOCUMENT' && (
                  <div>
                    <InputKey>
                      <input
                        type="text"
                        placeholder="Nombre del Documento"
                        value={firstStageData.fileName || ''}
                        onChange={e =>
                          handleFileNameInputChange(e.target.value)
                        }
                      />
                    </InputKey>
                  </div>
                )}
              </>
            )}
        </SelectFileCont>

        <div>
          <Label margin="10px 0 0">Escenario</Label>

          <TemplateSelect
            name="scenarioSelect"
            onChange={e => handleSelectScenario(e.target.value)}
          >
            <option value="">
              {scenariosToRender.length === 0
                ? 'Cargando Escenarios'
                : 'Seleccionar Escenarios'}
            </option>
            {scenariosToRender.map((escenario, index) => {
              return (
                <option key={index} value={escenario.id}>
                  {escenario.name}
                </option>
              );
            })}
          </TemplateSelect>
        </div>

        <div>
          <FlexCont>
            <Label margin="0px 0 0">Palabra Clave</Label>
            <Label margin="0px 0 0">
              <input
                type="checkbox"
                checked={isManualKey}
                onChange={() => setIsManualKey(!isManualKey)}
              />
              Manual
            </Label>
          </FlexCont>

          <InputKey>
            <input
              disabled={!isManualKey}
              type="text"
              name="startingResponse"
              value={firstStageData.startingResponse}
              onChange={e => handleStartingResponseChange(e.target.value)}
            />
          </InputKey>
        </div>
      </RightBlock>
    </Cont>
  );
};
const Cont = styled.div`
  height: 420px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  background-color: ${lightBackgroundBlue};

  & > :last-child {
    flex-grow: 1;
  }
`;
const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 50%;

  & > :nth-child(2) {
    transform: scale(0.9) translate(-25px);
  }
`;
const InputGroup = styled.div`
  margin-bottom: 20px;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;

  flex-direction: column;

  & > input {
    width: 100%;
    font-size: 1rem;
    padding: 10px 10px;
    border: none;
  }
`;
const FilesButtonsCont = styled.div`
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const FileButton = styled.button`
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid ${lightGreen};

  &:hover {
    color: red;
    border: 1px solid red;
    text-decoration: line-through;
  }

  & > i {
    line-height: 0px;
    font-size: 1.2rem;
    font-weight: 00;
    display: inline-block;
    margin-left: 5px;
    transform: translateY(3px);
  }
`;
const RightBlock = styled.div`
  padding-left: 20px;
`;
const Label = styled.label<ILabel>`
  font-size: 0.7rem;
  font-weight: 500;
  margin: ${props => props.margin};
`;
const AppSelect = styled.select`
  width: 100%;
  margin: 8px 0;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const TemplateSelect = styled(AppSelect)``;
const SelectFileCont = styled.div`
  height: 120px;
`;
const MessageCont = styled.div`
  margin: 0px 0 0px;
  height: 120px;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid ${primary};
  background-color: white;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const FlexCont = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    margin-right: 5px;
    transform: scale(0.8) translateY(3px);
  }
`;
const InputKey = styled(InputGroup)`
  margin-top: 5px;
`;
interface ILabel {
  margin?: string;
}
export default CreateWaCampaignFirstStage;
