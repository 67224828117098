import React, {
  useCallback,
  useRef,
  useState,
  DragEventHandler,
  useEffect,
} from 'react';
import styled from 'styled-components';

import { v4 as uuid } from 'uuid';

import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Edge,
  Connection,
  ReactFlowInstance,
  ReactFlowProvider,
  Node,
  ConnectionLineType,
  MarkerType,
  updateEdge,
  NodeMouseHandler,
} from 'reactflow';
import 'reactflow/dist/style.css';
import FlowSideBar from './sidebar/sidebar';

import './index.css';

import nodeTypes, {
  CustomNodeTypes,
  INodeData,
  nodeData,
} from './utils/nodeTypes';
import ContextMenu from './contextMenu';
import elementsId from './utils/elementsId';
import Modal from './modals/modal';
import SearchNode from './modals/search';
import { darkBorder, primary } from 'library/colors';
import { useHistory, useParams } from 'react-router-dom';
import {
  ICreateScenario,
  ISceneryGlobals,
} from 'library/interfaces/scenariosInterfaces';
import { INIT_SCENERY_GLOBALS } from 'library/services/scenarioServices';
import { getScenarioById, updateScenario, getFunctions, getIntents, addScenario, updateIsEdited } from 'store/scenariosV2/reducer';
import { useAppSelector } from 'library/hooks/useAppSelector';
import LoadingImg from 'components/Configuration/atoms/loading';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { getDatabases } from 'store/databases/asyncActions';
import GoBackAlert from './alerts/goBackAlert';
import ChatTester from './flowTester/chatTexter';

const FlowV2 = () => {
  const { scenarioId } = useParams<{ scenarioId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory()
  const { scenario: selectedScenario, isLoading, newScenarioId, isEdited } = useAppSelector(
    store => store.scenariosV2
  );
  const [sceneryGlobals, setSceneryGlobals] =
    useState<ISceneryGlobals>(INIT_SCENERY_GLOBALS);

  const reactFlowWrapper = useRef<HTMLDivElement>(null);

  const edgeUpdateSuccessful = useRef(true);

  const [nodes, setNodes, onNodesChange] = useNodesState<INodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null);

  const [selectedNode, setSelectedNode] = useState<Node<
    INodeData,
    CustomNodeTypes
  > | null>(null);
  const [selectedEdges, setSelectedEdges] = useState<Edge<any>[] | null>(null)

  const [nodeContextMenu, setNodeContextMenu] = useState({
    show: false,
    position: { x: 0, y: 0 },
  });

  const [showModal, setShowModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showGoBackAlert, setShowGoBackAlert] = useState(false)
  const [showChatTester, setShowChatTester] = useState(false)

  /**
   * Esta funcion actualiza el store para indicar que el escenario ha sido actualizado.
   * @param edited Si el escenario es editado. Cuando se guarda el escenario se debe pasar false. 
   */
  const handleSceneryIsEdited = (edited = true) => {
    if (isEdited) return
    dispatch(updateIsEdited(edited))
  }

  const handleGoBackButtonClick = () => {
    if (isEdited) {
      setShowGoBackAlert(true)
      return
    }
    history.push('/incoming-v2')
  }


  const onConnect = useCallback(
    (params: Edge<any> | Connection) => {
      const node = nodes.find(node => node.id === params.source);

      if (!node) return;

      const souces = node.data.sources || [];

      const sourceColor =
        souces.find(hand => hand.id === params.sourceHandle)?.color ||
        '#85ce5f';

      const newEdge = params as Edge;

      newEdge.type = 'smoothstep';
      newEdge.markerEnd = {
        type: MarkerType.Arrow,
        color: sourceColor,
        width: 15,
        height: 15,
        strokeWidth: 2,
      };
      newEdge.style = { stroke: sourceColor, strokeWidth: '2' };

      setEdges(eds => {
        const newEds = addEdge(params, eds);

        return newEds;
      });
      handleSceneryIsEdited()
    },
    [setEdges, nodes],
  );

  /**
   * delete edge when is deleted a source 
   * from interactive menu, conditional or GTP nodes
   * 
   * @param sourceId string
   */
  const handleDeleteEdgeOnDeleteSource = (sourceId: string) => {
    if (!selectedEdges) return
    setSelectedEdges(selectedEdges.filter(e => e.sourceHandle !== sourceId))
  }

  const handleUpdateEdgeColor = (nodeId: string, sourceId: string, color: string) => {
    if (!selectedEdges) return
    const newEdges = selectedEdges.map(edg => {
      if (edg.id.startsWith(`reactflow__edge-${nodeId + sourceId}`)) {
        return {
          ...edg,
          style: {
            ...edg.style,
            stroke: color
          },
          markerEnd: {
            type: MarkerType.Arrow,
            color: color,
            width: 15,
            height: 15,
            strokeWidth: 2,
          }
        }
      }
      return edg
    })
    setSelectedEdges(newEdges)
  }

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      edgeUpdateSuccessful.current = true;
      setEdges(els => updateEdge(oldEdge, newConnection, els));
    },
    [],
  );

  const onEdgeUpdateEnd = useCallback(
    (_: MouseEvent | TouchEvent, edge: Edge) => {
      if (!edgeUpdateSuccessful.current) {
        setEdges(eds => eds.filter(e => e.id !== edge.id));
      }
      edgeUpdateSuccessful.current = true;
      handleSceneryIsEdited()
    },
    [],
  );

  const handleDeleteNode = () => {
    if (!reactFlowInstance || !selectedNode) return;
    setNodeContextMenu(prev => ({ ...prev, show: false }));
    reactFlowInstance.deleteElements({ nodes: [selectedNode] });
    setSelectedNode(null);
    handleSceneryIsEdited()
  };

  const handleCopyNode = () => {
    if (!selectedNode) return;

    const newCopyNode = structuredClone(selectedNode)
    newCopyNode.id = uuid()
    newCopyNode.position.x += 50
    newCopyNode.position.y += 50

    if(newCopyNode.positionAbsolute){
      newCopyNode.positionAbsolute.x += 50
      newCopyNode.positionAbsolute.y += 50
    }

    newCopyNode.data.title += ' Copy'

    newCopyNode.data.responseRoutes = newCopyNode.data.responseRoutes?.map((item)=>({...item, id: uuid()})) 

    setNodes(nds => [newCopyNode, ...nds.map(item=>({...item, selected: false}))]);
    setNodeContextMenu(prev => ({ ...prev, show: false }));
    setSelectedNode(null);
    handleSceneryIsEdited()
  }
  
  const handleUpdateNode = () => {
    setNodes(prev =>
      prev.map(nod => {
        if (nod.id === selectedNode?.id) return selectedNode;
        return nod;
      }),
    );
    setShowModal(false);
    handleSceneryIsEdited()
    if (!selectedEdges) return
    setEdges(selectedEdges)
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop: DragEventHandler<HTMLDivElement> = useCallback(
    event => {
      event.preventDefault();

      if (reactFlowWrapper.current === null) return;

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData(
        'application/reactflow',
      ) as CustomNodeTypes;

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type || !(type in nodeData)) {
        return;
      }

      if (reactFlowInstance === null) return;

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNodeData = nodeData[type];
      const newNode = {
        id: uuid(),
        type,
        position,
        data: newNodeData,
      };
      setNodes(nds => nds.concat(newNode));
      handleSceneryIsEdited()
    },
    [reactFlowInstance],
  );

  const handleShowSearch = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

    const clickedElement = event.target as HTMLDivElement;
    const closestElememt = clickedElement.closest('[id]');
    const parentId = closestElememt?.id;
    if (parentId !== 'SearchModal') return
    setShowSearch(prev => !prev)
  }

  const centerSearchedNode = (nodeId: string) => {
    if (reactFlowInstance === null || !nodeId) return;
    reactFlowInstance.fitView({
      nodes: [{ id: nodeId }],
      duration: 1000,
      maxZoom: 1,
    });
    setShowSearch(false)
  };

  /**
   * Node context menú
   */
  const onNodeContextMenu = (
    event: React.MouseEvent,
    node: Node<INodeData>,
  ) => {
    event.preventDefault();

    const typedNode = node as Node<INodeData, CustomNodeTypes>;

    if (typedNode.type === 'sceneryStart') return;

    if (reactFlowWrapper.current === null) return;

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

    setSelectedNode(typedNode);
    setNodeContextMenu(prev => ({
      ...prev,
      show: true,
      position: {
        x: event.clientX - reactFlowBounds.left, //- 265,
        y: event.clientY - reactFlowBounds.top, //- 145,
      },
    }));
  };

  const onNodeDoubleClick: NodeMouseHandler = (
    event: React.MouseEvent,
    node: Node<INodeData>,
  ) => {
    event.preventDefault();

    const clonedNode = structuredClone(node) as Node<
      INodeData,
      CustomNodeTypes
    >;
    const clonedEdges = structuredClone(edges)
    if (clonedNode.type === 'sceneryEnd') return;
    setSelectedNode(clonedNode);
    setSelectedEdges(clonedEdges)
    setShowModal(true);
  };

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    setSelectedNode(null);
    setSelectedEdges(null)
    setShowModal(false);
  };

  const handleSaveNode = () => {
    if (!reactFlowInstance) return
    const scenario: ICreateScenario = {
      ...sceneryGlobals,
      workflow: {
        nodes: nodes as Node<INodeData, CustomNodeTypes>[],
        edges,
        viewport: reactFlowInstance.toObject().viewport
      }
    }
    dispatch(updateIsEdited(false))
    if (scenarioId === 'new') {
      dispatch(addScenario(scenario));
      return
    }
    dispatch(updateScenario({ scenarioId, scenario }))
  };

  /**
   * add sceneryStart node when is a new scenery
   */
  useEffect(() => {
    if (scenarioId !== 'new' || reactFlowWrapper.current === null || !reactFlowInstance) return
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

    //initial position of node
    const top = (window.innerHeight * .5) - 100

    const position = reactFlowInstance.project({
      x: reactFlowBounds.left + 300,
      y: reactFlowBounds.top + top,
    });

    const newNode = {
      id: uuid(),
      type: 'sceneryStart',
      position,
      data: nodeData.sceneryStart,
    };
    const clonedNode = structuredClone(newNode) as Node<
      INodeData,
      CustomNodeTypes
    >;

    setNodes(nds => {
      const newNds = nds.concat(newNode)
      return newNds
    });

    setSelectedNode(clonedNode);
    setShowModal(true);

  }, [reactFlowInstance])

  useEffect(() => {
    if (newScenarioId === null) return
    history.replace(`/incoming-v2/${newScenarioId}`)
  }, [newScenarioId])

  /**
   * get the scenary by id
   */
  useEffect(() => {
    dispatch(getFunctions())
    dispatch(getIntents())
    dispatch(getDatabases())

    if (scenarioId === 'new') return

    dispatch(getScenarioById(scenarioId));

  }, []);

  useEffect(() => {
    if (selectedScenario === null
      || !selectedScenario?.workflow
      || !selectedScenario?.name
      || !reactFlowInstance) return;

    const { nodes, edges } = selectedScenario.workflow;
    setSceneryGlobals({
      name: selectedScenario.name,
      settings: selectedScenario.settings,
      sources: selectedScenario.sources
    })
    setEdges(edges)
    setNodes(nodes);
    reactFlowInstance.setViewport(selectedScenario.workflow.viewport);

  }, [selectedScenario]);

  /**
   * capture clip and handle context menu exit
   */

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLDivElement;
      const closestElememt = clickedElement.closest('[id]');
      const parentId = closestElememt?.id;

      // hide context menú
      if (nodeContextMenu.show && parentId && !(parentId in elementsId)) {
        setSelectedNode(null);
        setNodeContextMenu(prev => ({ ...prev, show: false }));
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [nodeContextMenu.show]);

  return (
    <>
      <FlowCont className="dndflow" style={{ width: '100%', height: '1px' }}>
        <ReactFlowProvider>
          <FlowSideBar handleSaveNode={handleSaveNode} handleGoBackButtonClick={handleGoBackButtonClick} />
          <div
            className="reactflow-wrapper"
            ref={reactFlowWrapper}
            style={{ height: '100%' }}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              minZoom={0.1}
              maxZoom={10}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onEdgeUpdate={onEdgeUpdate}
              onEdgeUpdateStart={onEdgeUpdateStart}
              onEdgeUpdateEnd={onEdgeUpdateEnd}
              connectionLineType={ConnectionLineType.SmoothStep}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onNodeContextMenu={onNodeContextMenu}
              onNodeDoubleClick={onNodeDoubleClick}
            >
              {nodeContextMenu.show && (
                <ContextMenu
                  position={nodeContextMenu.position}
                  onDelete={handleDeleteNode}
                  onCopy={handleCopyNode}
                />
              )}
              <Background />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
        <Search show={showChatTester} onClick={e => handleShowSearch(e)} id={"SearchModal"}>
          <i className="bx bx-search"></i>
          {showSearch && (
            <SearchNode nodes={nodes} centerSearchedNode={centerSearchedNode} />
          )}
        </Search>
        {isLoading && <LoadingImg blur={3} zIndex={1000} />}
        <ChatTesterContainer show={showChatTester}>
          <Arrow show={showChatTester} onClick={() => setShowChatTester(!showChatTester)}>
            <i className='bx bxs-left-arrow'></i>
          </Arrow>
          <ChatTester sceneryId={scenarioId} />
        </ChatTesterContainer>
      </FlowCont>
      {showModal && selectedNode && (
        <Modal
          handleExit={handleExit}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          handleUpdateNode={handleUpdateNode}
          sceneryGlobals={sceneryGlobals}
          setSceneryGlobals={setSceneryGlobals}
          handleDeleteEdgeOnDeleteSource={handleDeleteEdgeOnDeleteSource}
          handleUpdateEdgeColor={handleUpdateEdgeColor}
        />
      )}
      {showGoBackAlert && <GoBackAlert hideAlert={() => setShowGoBackAlert(false)} />}
    </>
  );
};
const FlowCont = styled.div`
  position: relative;
`;
const Search = styled.div<IChatTester>`
  display: flex;
  position: absolute;
  transition: right 1s;
  right: ${props => props.show ? '420px' : '0'};
  top: 10px;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;

  & > i {
    color: ${primary};
    font-size: 1.5rem;
  }
`;

const ChatTesterContainer = styled.div<IChatTester>`
  /* border: 1px solid; */
  position: relative;
  transition: width 1s;
  width: ${props => props.show ? '420px' : '0'};
`;
const Arrow = styled.span<IChatTester>`
  position: absolute;
  top: 50%;
  left: -30px;
  cursor: pointer;
  font-size: 1.2rem;
  transform: translateY(-50%) rotateY(${props => props.show ? '180deg' : '0deg'});
  transition: transform 1s, color 1s;
  color: ${props => props.show ? darkBorder : ''};
`;
interface IChatTester {
  show: boolean
}
export default FlowV2;
