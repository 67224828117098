import React from 'react';
import styled from 'styled-components';

interface IContextMenuProps {
  position: {
    x: number;
    y: number;
  };
  onDelete: Function;
  onCopy: Function;
}
const ContextMenu = ({ position, onDelete, onCopy }: IContextMenuProps) => {
  return (
    <Cont id="nodeContextMenu" position={position}>
      <Button onClick={() => onCopy()}>Copiar</Button>
      <Hr />
      <Button onClick={() => onDelete()} danger>
        Eliminar
      </Button>
    </Cont>
  );
};
const Cont = styled.div<ICont>`
  position: absolute;
  left: ${props => props.position.x}px;
  top: ${props => props.position.y}px;
  z-index: 10000;
  /* padding: 10px; */
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
`;
const Button = styled.div<IButton>`
  padding: 10px;
  border-radius: 5px;
  &:hover {
    background-color: ${props => props.danger && 'rgba(255, 0, 0, .5)'};
  }
`;
const Hr = styled.hr`
  margin: 0;
`;
interface ICont {
  position: {
    x: number;
    y: number;
  };
}
interface IButton {
  danger?: boolean;
}
export default ContextMenu;
