import { primary } from 'library/colors';
import { ICreateCampaignFileContacts } from 'library/interfaces/campaignInterfaces';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IFile } from '../molecules/FormLoadingFiles';

interface IFormFileConfig {
  file: IFile;
  FileIndex: number;
  setFileContacts: (
    index: number,
    contacts: ICreateCampaignFileContacts[],
  ) => void;
  handleSelectColumnToSave: (fileIndex: number, fileUpdated: IFile) => void;
}
const FormFileConfig: FC<IFormFileConfig> = ({
  file,
  FileIndex,
  setFileContacts,
  handleSelectColumnToSave,
}) => {
  const { t } = useTranslation('outbound');
  const [selectedSheet, setSelectedSheet] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');

  let sheetData = file.sheets.filter(item => {
    if (item.name === selectedSheet) return item;
  });
  const contactsArray = sheetData[0]?.data;

  const handleSelectSheetChange = (value: string) => {
    setSelectedSheet(value);
    setSelectedColumn('');
  };

  const handleSelectColumnChange = (value: string) => {
    setSelectedColumn(value);

    //filter the sheet data array to get the contact number

    const contact: ICreateCampaignFileContacts[] = [];

    sheetData[0]?.data.map((excelRow, i) => {
      if (!(excelRow instanceof Object)) return;

      for (const column in excelRow) {
        if (column === value) {
          /* @ts-ignore */
          const number = `${excelRow[value]}`;
          /* @ts-ignore */
          contact.push({ contact: number, e64_data: [] });
        }
      }
    });
    setFileContacts(FileIndex, contact);
  };

  // esta funcion maneja el check de las columnas del e64
  // pero tiene que tener en cuenta la columna editable
  const handleCheckColumnToSave = (
    columnIndex: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { id, checked } = event.target;
    const selectedColumns: string[] = [];
    let editableColumn = ''

    const editedFile = file;

    const editedSheets = file.sheets.map(sheet => {
      if (sheet.name === selectedSheet) {
        sheet.columnsToSelect[columnIndex].isSelected = checked;

        sheet.columnsToSelect.forEach(
          column => column.isSelected && selectedColumns.push(column.name),
        );
        sheet.columnToEdit.forEach(column => {
          if(column.isSelected) editableColumn = column.name
        });
      }
      return sheet;
    });
    editedFile.sheets = [...editedSheets];

    const contact: ICreateCampaignFileContacts[] = [];

    sheetData[0]?.data.map((excelRow, i) => {
      if (!(excelRow instanceof Object)) return;

      for (const column in excelRow) {
        if (column === selectedColumn) {
          const e64_data: Object[] = [];
          selectedColumns.forEach(columnName =>
            /* @ts-ignore */
            e64_data.push({ [columnName]: excelRow[columnName] }),
          );
          /* @ts-ignore */
          /* @ts-ignore */
          const number = `${excelRow[selectedColumn]}`;
          const name = editableColumn ? `${excelRow[editableColumn]}` : '';
          contact.push({ contact: number, name: name, e64_data: e64_data });
        }
      }
    });
    setFileContacts(FileIndex, contact);

    handleSelectColumnToSave(FileIndex, editedFile);
  };

  const handleCheckColumnToEdit = (
    columnIndex: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    const selectedColumns: string[] = [];
    let editableColumn = ''


    const editedFile = file;

    const editedSheets = file.sheets.map(sheet => {
      if (sheet.name === selectedSheet) {
        
        sheet.columnsToSelect.forEach(
          column => column.isSelected && selectedColumns.push(column.name),
        );
        
        sheet.columnToEdit.forEach((colum,index)=>{
          if(columnIndex === index) {
            sheet.columnToEdit[columnIndex].isSelected = checked;
            editableColumn = colum.name
            return
          }
        sheet.columnToEdit[index].isSelected = false;
          
        })
      }
      return sheet;
    });
    editedFile.sheets = [...editedSheets];

    const contact: ICreateCampaignFileContacts[] = [];

    sheetData[0]?.data.map((excelRow, i) => {
      if (!(excelRow instanceof Object)) return;

      for (const column in excelRow) {
        if (column === selectedColumn) {
          const e64_data: Object[] = [];
          selectedColumns.forEach(columnName =>
            /* @ts-ignore */
            e64_data.push({ [columnName]: excelRow[columnName] }),
          );
          /* @ts-ignore */
          const number = `${excelRow[selectedColumn]}`;
          const name = editableColumn ? `${excelRow[editableColumn]}` : '';
          contact.push({ contact: number, name: name, e64_data: e64_data });
        }
      }
    });
    setFileContacts(FileIndex, contact);

    handleSelectColumnToSave(FileIndex, editedFile);
  };
  return (
    <Cont>
      <FlexCont>
        <FileName>
          {t('campaigns.createCampaignForm.contactSources.file')}:{' '}
          <b>{file.fileName}</b>
        </FileName>
        {selectedColumn !== '' && (
          <div>
            {t('campaigns.createCampaignForm.contactSources.contacts')}:{' '}
            {contactsArray.length}
          </div>
        )}
      </FlexCont>
      <FlexCont>
        <SheetSelect
          name="sheetsName"
          value={selectedSheet}
          onChange={e => handleSelectSheetChange(e.target.value)}
        >
          <option value="">
            {t('campaigns.createCampaignForm.contactSources.selectSheet')}
          </option>
          {file.sheetsName.map((sheetName, index) => {
            return (
              <option key={index} value={sheetName}>
                {sheetName}
              </option>
            );
          })}
        </SheetSelect>
        {selectedSheet !== '' && (
          <ColumnSelect
            name="column"
            value={selectedColumn}
            onChange={e => handleSelectColumnChange(e.target.value)}
          >
            <option value="">
              {t('campaigns.createCampaignForm.contactSources.column')}
            </option>
            {sheetData[0] &&
              sheetData[0].columnsToSelect.map((column, index) => {
                return (
                  <option key={index} value={column.name}>
                    {column.name}
                  </option>
                );
              })}
          </ColumnSelect>
        )}
      </FlexCont>
      {selectedColumn && sheetData[0] && (
        <GridCont>
          <GridContChild>
            <Title>
              {t(
                'campaigns.createCampaignForm.contactSources.saveInformationAsk',
              )}
            </Title>
            <ColumnsToSelectCont>
              {sheetData[0].columnsToSelect.map((column, columnIndex) => {
                return (
                  <SelectableColumn key={columnIndex}>
                    <input
                      type="checkbox"
                      id={column.name}
                      checked={column.isSelected}
                      onChange={e => handleCheckColumnToSave(columnIndex, e)}
                    />
                    <label htmlFor={column.name}>{column.name}</label>
                  </SelectableColumn>
                );
              })}
            </ColumnsToSelectCont>
          </GridContChild>
          <GridContChild>
            <Title>
              {t(
                'campaigns.createCampaignForm.contactSources.saveInformationToEdit',
              )}
            </Title>
            <ColumnsToSelectCont>
              {sheetData[0].columnToEdit.map((column, columnIndex) => {
                return (
                  <SelectableColumn key={columnIndex}>
                    <input
                      type="checkbox"
                      id={column.name}
                      checked={column.isSelected}
                      onChange={e => handleCheckColumnToEdit(columnIndex, e)}
                    />
                    <label htmlFor={column.name}>{column.name}</label>
                  </SelectableColumn>
                );
              })}
            </ColumnsToSelectCont>
          </GridContChild>
        </GridCont>
      )}
    </Cont>
  );
};
const Cont = styled.div`
  margin-bottom: 20px;
`;
const FlexCont = styled.div`
  display: flex;
`;
const FileName = styled.div`
  font-size: 0.9rem;
  margin-bottom: 5px;
  flex-grow: 1;
`;
const SheetSelect = styled.select`
  width: 50%;
  margin-right: 20px;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const GridCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;
const GridContChild = styled.div``;
const ColumnSelect = styled.select`
  flex-grow: 1;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const Title = styled.div`
  font-size: 0.9rem;
  font-weight: 300;
  margin: 25px 0 0;
`;
const ColumnsToSelectCont = styled.div`
  max-height: 145px;
  padding: 0px 0 0 10px;
  border-radius: 10px;
  background-color: white;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const SelectableColumn = styled.div`
  display: flex;
  margin: 5px 0 10px;

  & > label {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 10px;
  }
`;
export default FormFileConfig;
