import {
  darkBorder,
  lightBackground,
  lightBackgroundBlue,
  primary,
} from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { IReportData } from 'library/interfaces/crdInterfaces';
import React, { useEffect, useMemo, useState } from 'react';
import {
  deleteLiveConversationSessionAction,
  getAllSessionsFromMonitor,
  getLiveConversationSessionMessagesAction,
  getLiveConversationsSessionLogsAction,
  putLiveConversationTranferSessionAction,
  putRestoreWaSessionAction,
} from 'store/actions';
import styled from 'styled-components';
import LiveConversationSession from '../molecules/liveConversationSession';
import ChatPopup from '../organisms/chatPopup';
import LogsPopup from '../organisms/logsPopup';
import ReassignPopup from '../organisms/reassignPopup';
import { ILiveConversationSession } from 'store/liveConversations/reducer';

const Conversations = () => {
  const dispatch = useAppDispatch();
  const { users, isLoading: reportLoading } = useAppSelector(
    store => store.cdr.reportData,
  ) as IReportData;
  const { isLoading, sessions } = useAppSelector(
    store => store.liveConversations.sessions,
  );
  const templates = useAppSelector(
    store => store.waCampaigns.templates.templates,
  );

  const [selectedAgent, setSelectedAgent] = useState('');
  const [sessionStateFilter, setSessionStateFilter] = useState<boolean | null>(
    null,
  );

  const [buttonsState, setButtonsState] = useState(BUTTONS_STATE);

  const [showChat, setShowChat] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [currentSessionId, setCurrentSessionId] = useState('');

  const sessionsToRender: ILiveConversationSession[] = useMemo(() => {
    let filteredSessions: ILiveConversationSession[] = sessions;
    if (selectedAgent) {
      filteredSessions = filteredSessions.filter(
        ses => ses.assigned_to === selectedAgent,
      );
    }
    if (sessionStateFilter !== null) {
      filteredSessions = filteredSessions.filter(
        ses => ses.active === sessionStateFilter,
      );
    }
    return filteredSessions;
  }, [sessions, selectedAgent, sessionStateFilter]);

  const handleShowChat = (
    channel: string,
    source: string,
    sessionId: string,
  ) => {
    setShowChat(!showChat);
    dispatch(
      getLiveConversationSessionMessagesAction(
        channel,
        source,
        sessionId,
        templates,
      ),
    );
  };

  const handleShowLogs = (sessionId: string) => {
    setShowLogs(!showLogs);
    dispatch(getLiveConversationsSessionLogsAction(sessionId));
  };

  const handleDeleteSession = (sessionId: string) => {
    dispatch(deleteLiveConversationSessionAction(sessionId));
  };

  const handleRestoreSession = (sessionId: string) => {
    dispatch(putRestoreWaSessionAction(sessionId));
  };

  const handleShowReasignUsers = (agentId: string) => {
    if (!currentSessionId) return;
    setShowUsers(!showUsers);
    dispatch(
      putLiveConversationTranferSessionAction(currentSessionId, agentId),
    );
  };

  const handleUpdateButtonsState = (index: number) => {
    setButtonsState(prev =>
      prev.map((but, i) => {
        if (index === i) {
          setSessionStateFilter(but.value);
          return { ...but, isSelected: true };
        }
        return { ...but, isSelected: false };
      }),
    );
  };

  useEffect(() => {
    if (users.length === 0 || isLoading || reportLoading) return;
    dispatch(getAllSessionsFromMonitor(users));
  }, [users]);

  return (
    <Cont>
      <BlueCont>
        <Header>
          <SelectCont>
            <label htmlFor="users">Selecciona un Agente</label>
            <select
              name="users"
              id="users"
              value={selectedAgent}
              onChange={e => setSelectedAgent(e.target.value)}
            >
              <option value="">Todos los agentes</option>
              {users.map((user, index) => {
                return (
                  <option key={index} value={user.auth0_id}>
                    {user.name}
                  </option>
                );
              })}
            </select>
          </SelectCont>
          <ButtonsCont>
            <label>Filtrar por estado de sesion</label>
            <div>
              {buttonsState.map((but, index) => {
                return (
                  <ButtonState
                    key={index}
                    isSelected={but.isSelected}
                    onClick={() => handleUpdateButtonsState(index)}
                  >
                    {but.label}
                  </ButtonState>
                );
              })}
            </div>
          </ButtonsCont>
        </Header>
        {sessionsToRender.map((session, index) => {
          return (
            <LiveConversationSession
              key={index}
              session={session}
              users={users}
              waCampaigns={[]}
              showChat={handleShowChat}
              showLogs={handleShowLogs}
              showUsers={setShowUsers}
              setCurrentSessionId={setCurrentSessionId}
              deleteSession={handleDeleteSession}
              restoreSession={handleRestoreSession}
            />
          );
        })}
      </BlueCont>

      {showChat && <ChatPopup setShowChat={setShowChat} />}
      {showLogs && <LogsPopup setShowLogs={setShowLogs} />}
      {showUsers && (
        <ReassignPopup
          setShowReassign={setShowUsers}
          users={users}
          handleShowReasignUsers={handleShowReasignUsers}
        />
      )}
    </Cont>
  );
};

const Cont = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${lightBackground};

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const BlueCont = styled.div`
  border-radius: 10px;
  flex-grow: 1;
  padding: 10px;
  background-color: ${lightBackgroundBlue};
`;
const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
`;
const SelectCont = styled.div`
  margin: 0 20px 0 0;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > label {
    font-size: 0.7rem;
  }

  & > select {
    margin: 0 0 5px;
    height: 44px;
    font-size: 1rem;
    border: none;
    padding: 10px 10px;
    flex-grow: 1;
    max-width: 230px;
    background-color: white;
  }
`;
const ButtonsCont = styled.div`
  font-size: 0.7rem;

  & > div {
    height: 40px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;
const ButtonState = styled.span<IButtonState>`
  padding: 5px 10px;
  border-radius: 50px;
  cursor: pointer;
  color: ${props => (props.isSelected ? '#fff' : '#000')};
  background-color: ${props => (props.isSelected ? primary : darkBorder)};
`;
interface IButtonState {
  isSelected: boolean;
}
export default Conversations;

const BUTTONS_STATE: {
  label: 'Activas' | 'Cerradas' | 'Todas';
  value: boolean | null;
  isSelected: boolean;
}[] = [
  { label: 'Todas', value: null, isSelected: true },
  { label: 'Activas', value: true, isSelected: false },
  { label: 'Cerradas', value: false, isSelected: false },
];
