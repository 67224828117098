import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { danger, darkBorder, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { IChannel } from 'library/interfaces/channelsInterfaces';
import { ISendHSM } from 'library/interfaces/conversationInterfaces';
import IStore from 'library/interfaces/storeInterface';
import {
  IWaCampaign,
} from 'library/interfaces/whatsappCampaignInterfaces';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGupshupAppTemplatesAction,
  getChannelsAction,
  postSendHSMAction,
} from 'store/actions';
import { IChannelStore } from 'store/channels/reducer';
import { IHSMStore } from 'store/conversation/reducer';
import { getAllFilesAction } from 'store/files/asyncActions';
import { IWACampaignStore } from 'store/outboundCampaigns/waCampaigns/reducer';
import styled from 'styled-components';

interface ISendWaTemplateProps { }
const SendWaTemplate: FC<ISendWaTemplateProps> = ({ }) => {
  const dispatch = useDispatch();
  const { isLoading, channels } = useSelector<IStore, IChannelStore>(
    store => store.channels,
  );
  const { templates, campaigns } = useSelector<IStore, IWACampaignStore>(
    store => store.waCampaigns,
  );
  const hsm = useSelector<IStore, IHSMStore>(store => store.conversations.hsm);
  const [hsmIsValid, setHsmIsValid] = useState(false);
  const [sendWaTemplateState, setSendWaTemplateState] = useState<{
    destination: string;
    startingResponse: string;
    campaign: IWaCampaign | null;
    channel: IChannel | null;
    splitedMessage: string[];
    variablesValues: Map<string, { index: number; value: string }>;
    variables: string[];
    fileUrl: string
  }>({
    destination: '',
    startingResponse: '',
    campaign: null,
    channel: null,
    splitedMessage: [],
    variablesValues: new Map(),
    variables: [],
    fileUrl: ''
  });

  const files = useAppSelector(store => store.files)

  const templateType = useMemo(() => {
    if (!sendWaTemplateState.campaign || templates.templates.length === 0) return 'TEXT'
    const { template_id } = sendWaTemplateState.campaign
    const template = templates.templates.find(temp => temp.id === template_id)
    if (!template) return 'TEXT'

    if (template.templateType === 'TEXT') return 'TEXT'

    return template.templateType

  }, [sendWaTemplateState.campaign, templates.templates])

  const filesToRender = useMemo(() => {
    if (files.files.length === 0) return []
    if (templateType === 'DOCUMENT') {
      return files.files.filter(file => file.filename.endsWith('.pdf'))
    } else if (templateType === 'IMAGE') {
      return files.files.filter(file => file.filename.endsWith('.jpg'))
    }
    return []
  }, [files.files, templateType])

  const messageToRender = useMemo(() => {
    return sendWaTemplateState.splitedMessage.join('');
  }, [sendWaTemplateState.splitedMessage]);

  const handleInputChange = (
    value: string,
    name: 'destination' | 'startingResponse',
  ) => {
    if (name === 'startingResponse') {
      return setSendWaTemplateState(prev => ({
        ...prev,
        [name]: value,
      }));
    }
    const regEx = /^\d+$/;
    if (!regEx.test(value) && value) return;
    setSendWaTemplateState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const hanleVariableInputChange = (
    varName: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    const newMap = new Map(sendWaTemplateState.variablesValues);
    const newSplitedMessage = [...sendWaTemplateState.splitedMessage];

    const varValue = newMap.get(varName);
    if (!varValue) return;
    varValue.value = value;
    newSplitedMessage[varValue.index] = value ? value : varName;

    setSendWaTemplateState(prev => ({
      ...prev,
      splitedMessage: newSplitedMessage,
      variablesValues: newMap,
    }));
  };

  const handleSelectCampaign = (campaignId: string) => {
    const campaign = campaigns.find(camp => camp.id === campaignId);
    if (!campaign) return
    dispatch(getGupshupAppTemplatesAction(campaign.app_id));
    setSendWaTemplateState(prev => ({
      ...prev,
      campaign: campaign || null,
    }));
  };

  const handleSendHSM = () => {
    if (
      !hsmIsValid ||
      !sendWaTemplateState.campaign ||
      !sendWaTemplateState.destination
    )
      return;
    let params: string[] = [];
    for (const values of sendWaTemplateState.variablesValues.values()) {
      params.push(values.value);
    }
    const hsm: ISendHSM = {
      destination: sendWaTemplateState.destination,
      params: params,
    };
    if (templateType === 'IMAGE') {
      hsm.message = {
        type: 'image',
        image: { link: sendWaTemplateState.fileUrl }
      }
    } else if (templateType === 'DOCUMENT') {
      hsm.message = {
        type: 'document',
        document: { link: sendWaTemplateState.fileUrl, filename: 'documento' }
      }
    }
    dispatch(postSendHSMAction(sendWaTemplateState.campaign.id, hsm));
  };

  // get wa channels and campaigns
  useEffect(() => {
    dispatch(getChannelsAction());
  }, []);

  // after select campaign set the template and values requires for app
  useEffect(() => {
    if (!sendWaTemplateState.campaign || templates.templates.length === 0)
      return;

    const template_id = sendWaTemplateState.campaign.template_id;
    const template = templates.templates.find(temp => temp.id === template_id);

    if (!template) return;

    const regEx = /({{+\w+}})/g;
    const splitedMessage = template.data.split(regEx);
    let variables: string[] = [];
    let variablesValues: Map<string, { index: number; value: string }> =
      new Map();
    splitedMessage.forEach((item, index) => {
      if (regEx.test(item)) {
        variablesValues.set(item, { index: index, value: '' });
        variables.push(item);
      }
    });
    setSendWaTemplateState(prev => ({
      ...prev,
      splitedMessage,
      variables,
      variablesValues,
    }));
  }, [templates.templates, sendWaTemplateState.campaign]);

  // validates if hsm is valid
  useEffect(() => {
    const { variablesValues, destination, channel, campaign } =
      sendWaTemplateState;

    if (!destination || !campaign) return setHsmIsValid(false);

    const varValues: string[] = [];
    for (const values of variablesValues.values()) {
      varValues.push(values.value);
    }
    if (varValues.some(val => val === '')) return setHsmIsValid(false);

    setHsmIsValid(true);
  }, [sendWaTemplateState]);

  // reset destination after hsm has been sent
  useEffect(() => {
    setSendWaTemplateState(prev => ({
      ...prev,
      destination: '',
    }));
  }, [hsm.messageId]);

  useEffect(() => {
    dispatch(getAllFilesAction())
  }, [])

  return (
    <Cont>
      <div>
        <Label margin="0px 0 0">Número Destino</Label>
        <NumbersInput
          type="text"
          name="destination"
          value={sendWaTemplateState.destination}
          onChange={e => handleInputChange(e.target.value, 'destination')}
        />
      </div>
      <div>
        <Label margin="10px 0 0">Selecciona la campaña</Label>

        <TemplateSelect
          name="campaign"
          onChange={e => handleSelectCampaign(e.target.value)}
        >
          <option value="">
            {isLoading ? 'Cargando Campañas' : 'Seleccionar Campaña'}
          </option>
          {campaigns.map((campaign, index) => {
            if (campaign.active === false) return
            return (
              <option key={index} value={campaign.id}>
                {campaign.name}
              </option>
            );
          })}
        </TemplateSelect>
      </div>

      {templates.isLoading && (
        <Loading>
          Cargando mensaje <LoadingPoints />
        </Loading>
      )}

      {templateType !== 'TEXT' &&
        <div>
          <Label margin="10px 0 0">Selecciona el archivo</Label>

          <TemplateSelect
            name="file"
            onChange={e => setSendWaTemplateState(prev => {
              return { ...prev, fileUrl: e.target.value }
            })}
          >
            <option value="">
              {isLoading ? 'Cargando archivos' : 'Seleccionar archivo'}
            </option>
            {filesToRender.map((file, index) => {
              return (
                <option key={index} value={file.url}>
                  {file.filename}
                </option>
              );
            })}
          </TemplateSelect>
        </div>
      }

      {
        sendWaTemplateState.splitedMessage.length > 0 && (
          <>
            <div>
              <Label margin="10px 0 0">
                Su mensaje tiene {sendWaTemplateState.variables.length} variables
              </Label>
              <MessageCont>{messageToRender}</MessageCont>
            </div>

            {sendWaTemplateState.variables.map((varName, index) => {
              return (
                <NumbersInput
                  key={index}
                  placeholder={`Variable ${varName}`}
                  value={sendWaTemplateState.variablesValues.get(varName)?.value}
                  onChange={e => hanleVariableInputChange(varName, e)}
                />
              );
            })}
            {!hsm.isLoading && !hsm.error && (
              <Button disabled={!hsmIsValid} onClick={() => handleSendHSM()}>
                Enviar
              </Button>
            )}
            {hsm.isLoading && (
              <LoadingButton disabled={true}>
                Enviando <LoadingPoints />
              </LoadingButton>
            )}
            {!hsm.isLoading && hsm.error && (
              <ErrorButton disabled={!hsmIsValid} onClick={() => handleSendHSM()}>
                Reenviar
              </ErrorButton>
            )}
          </>
        )
      }
    </Cont >
  );
};
const Cont = styled.div`
  height: 100%;
  width: 200px;
  padding: 20px 10px;
  font-size: 0.8rem;
  transform: translate(0px);
  position: absolute;
  left: 0;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const Label = styled.label<ILabel>`
  font-size: 0.7rem;
  font-weight: 500;
  margin: ${props => props.margin};
`;
const AppSelect = styled.select`
  width: 100%;
  margin: 8px 0;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const TemplateSelect = styled(AppSelect)``;

const Loading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const NumbersInput = styled.input`
  width: 100%;
  margin: 8px 0;
  padding-left: 5px;
  border-radius: 10px;
  border: 2px solid ${primary};
`;
const MessageCont = styled.div`
  margin: 10px 0;
  height: 130px;
  padding: 5px;
  border-radius: 10px;
  color: black;
  border: 2px solid ${primary};
  background-color: white;
  overflow: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const Button = styled.button`
  color: white;
  font-size: 0.8rem;
  width: 100%;
  padding: 5px 0;
  margin-top: 5px;
  border: 1px solid ${props => (props.disabled ? darkBorder : primary)};
  border-radius: 50px;
  background-color: ${props => (props.disabled ? darkBorder : primary)};
`;
const LoadingButton = styled(Button)`
  color: ${darkBorder};
  border: 1px solid ${darkBorder};
  background-color: transparent;
`;
const ErrorButton = styled(Button)`
  border: 1px solid ${danger};
  background-color: ${danger};
`;
interface ILabel {
  margin?: string;
}
export default SendWaTemplate;
