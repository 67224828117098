import axios from 'axios';
import { del, get, post, postNoDestructuring, put } from './api_helper';
import {
  delAws,
  getAws,
  postAws,
  postAwsWithoutDataDestructuring,
  putAws,
} from './api_aws_helper';
import * as url from './url_helper';

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = 'Sorry! the page you are looking for could not be found';
            break;
          case 500:
            message =
              'Sorry! something went wrong, please contact our support team';
            break;
          case 401:
            message = 'Invalid credentials';
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = 'Sorry! the page you are looking for could not be found';
            break;
          case 500:
            message =
              'Sorry! something went wrong, please contact our support team';
            break;
          case 401:
            message = 'Invalid credentials';
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } });

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES);

// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = '') =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

export const setQueueACW = (agentId, queueId) =>
  put(`${url.AGENTS}/${agentId}/queue/${queueId}/acw`);

export const setCloseConversation = (agentId, queueId) =>
  put(`${url.AGENTS}/${agentId}/queue/${queueId}/close`);

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

// get contacts
export const getUsers = () => get(url.GET_USERS);

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = user => put(url.UPDATE_USER, user);

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS);

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail);

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } });

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS);

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS);

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS);

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS);

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

// Scenarios
export const getScenarios = () => get(url.SCENARIOS);
export const getScenario = id => get(`${url.SCENARIOS}/${id}`);
export const addScenario = scenario => post(url.SCENARIOS, scenario);
export const renameScenario = (id, name) =>
  put(`${url.SCENARIOS}/${id}/rename`, name);
export const deleteScenario = id => del(`${url.SCENARIOS}/${id}`);
export const publishScenario = id => put(`${url.SCENARIOS}/${id}/publish`);
export const saveAsDraftScenario = id =>
  put(`${url.SCENARIOS}/${id}/saveasdraft`);
export const updateScenario = (id, data) => put(`${url.SCENARIOS}/${id}`, data);
export const updateFlowScenario = (id, flow) =>
  put(`${url.SCENARIOS}/${id}/flow`, flow);

// AGENTS
export const getAgents = groups => {
  let grps = '';
  if (groups) {
    grps = `groups=${groups.join()}`;
  }
  return get(`${url.AGENTS}${grps}`);
};
export const addNewAgent = (id, agent) => post(`${url.AGENTS}/${id}`, agent);
export const updateAgent = (id, agent) => put(`${url.AGENTS}/${id}`, agent);
export const deleteAgent = id => del(`${url.AGENTS}/${id}`);

// USERS
export const getFetch = () => get(`${url.USERS}/fetch`);

// CDR
/* export const  getLiveData = (owner, queues) => getAws(`${url.CDR}/live/${owner}?queues=${queues.join()}`)
export const getSummarizeData = (owner, period, timezone, queues) => 
  getAws(`${url.CDR}/summarized/${owner}?queues=${queues.join()}&period=${period}&timezone=${timezone}`) */

export const getLiveData = (owner, queues) =>
  postAws(`/live`, { owner, queues });
export const getSummarizeData = (owner, period, timezone, queues) =>
  postAws(`/summarize`, { owner, period, timezone, queues });
export const getReportData = () => get(`${url.CDR}/data`);
export const getAgentsServiceLevel = (owner, start_date, end_date) =>
  getAws(
    `${url.AGENTS}/summarize/owner/${owner}?start=${start_date}&end=${end_date}`,
  );

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};

// RECORDINGS
export const getQueues = () => get(url.GET_QUEUES);

export const getRecordings = (owner, limit) =>
  getAws(
    `${url.GET_CALLS_RECORDINGS}/owner/${owner}${
      limit ? `?limit=${limit}` : ''
    }`,
  );

export const getPaginationNext = (owner, LastKey, limit) =>
  getAws(
    `${url.GET_CALLS_RECORDINGS}/owner/${owner}?limit=${limit}&last=${LastKey}`,
  );
//export const getRecordingsFilterDate = (owner, start, end, startTime, endTime, limit, order) => getAws(`${url.GET_CALLS_RECORDINGS}/owner/${owner}?limit=${limit}&order=${order}&start=${start}T${startTime}:00.000Z&end=${end}T${endTime}:59.999Z`);
export const getRecordingsByFilter = (
  owner,
  startDate,
  endDate,
  filter,
  value,
  limit,
  order,
) =>
  getAws(
    `${url.GET_CALLS_RECORDINGS}/owner/${owner}?limit=${limit}&order=${order}&start=${startDate}&end=${endDate}&filterBy=${filter}&filterValue=${value}`,
  );
export const getRecordingsByQueue = (owner, queue, limit, order) =>
  getAws(
    `${url.GET_CALLS_RECORDINGS}/owner/${owner}?limit=${limit}&order=${order}&queue=${queue}`,
  );

export const getRecordingsByAllFilters = (
  owner,
  queue,
  startDate,
  endDate,
  filter,
  value,
  limit,
  order,
) =>
  getAws(
    `${url.GET_CALLS_RECORDINGS}/owner/${owner}?limit=${limit}&order=${order}&start=${startDate}&end=${endDate}&filterBy=${filter}&filterValue=${value}&queue=${queue}`,
  );

export const getRecordingsFromReportModule = (
  owner,
  limit,
  order,
  start_date,
  end_date,
  filters,
) =>
  getAws(
    `${url.GET_RECORDING_FROM_REPORT_MODULE}/owner/${owner}?limit=${limit}&order=${order}&start=${start_date}&end=${end_date}&${filters}`,
  );

export const postFilters = filters => post(url.POST_FILTERS, filters);

// Chatbot

export const getChatBot = (owner, uniqueId) =>
  getAws(`${url.CHATBOT_NOTES}/owner/${owner}/call/${uniqueId}?withLogs=true`);

// Voice Campaigns
export const getAllCampaigns = owner =>
  getAws(`${url.CAMPAIGNS}?owner=${owner}`);
export const getCampaignById = id => getAws(`${url.CAMPAIGNS}/${id}`);
export const getPBXCampaignInfo = () => get(`${url.PBX_CAMPAIGN_INFO}`);
export const postCreateCampaign = campaign =>
  postAws(`${url.CAMPAIGNS}`, campaign);
export const postContactsToCampaign = (campaignId, ContactsArr20) =>
  postAwsWithoutDataDestructuring(
    `${url.CAMPAIGNS}/${campaignId}/contact`,
    ContactsArr20,
  );
export const putUpdateCampaign = (id, campaign) =>
  putAws(`${url.CAMPAIGNS}/${id}`, campaign);
export const putUpdateCampaignStatus = (id, action) =>
  putAws(`${url.CAMPAIGNS}/${id}/${action}`);
export const deleteCampaign = id => delAws(`${url.CAMPAIGNS}/${id}`);

export const getCampaignTagsSummarized = campaignId =>
  getAws(`${url.CAMPAIGNS}/${campaignId}/tag/summarized`);
export const postCampaignCallTag = (tag, campaignId) =>
  postAws(`${url.CAMPAIGNS}/${campaignId}/tag`, tag);

// SMS campaign
export const getSMSCampaigns = () => get(`${url.CAMPAIGNS}/sms-v2`);
export const getSMSCampaignByID = smsCampaignId =>
  get(`${url.CAMPAIGNS}/sms-v2/${smsCampaignId}`);
export const postSMSCampaign = smsCampaign =>
  post(`${url.CAMPAIGNS}/sms-v2`, smsCampaign);
export const postSMSCampaignContacs = (smsCampaignId, contacts) =>
  post(`${url.CAMPAIGNS}/sms-v2/${smsCampaignId}/contact`, contacts);
export const putChangeSMSCampaignStatus = (smsCampaignId, action) =>
  put(`${url.CAMPAIGNS}/sms-v2/${smsCampaignId}/${action}`);

// WA campaigns
export const getWACampaigns = () => get(`${url.CAMPAIGNS}/wa`);
export const getWACampaignByID = waCampaignId =>
  get(`${url.CAMPAIGNS}/wa/${waCampaignId}`);
export const postWACampaign = waCampaignToCreate =>
  post(`${url.CAMPAIGNS}/wa`, waCampaignToCreate);
export const postWACampaignContacs = (waCampaignId, contacts) =>
  post(`${url.CAMPAIGNS}/wa/${waCampaignId}/contact`, contacts);
export const putChangeWACampaignStatus = (waCampaignId, action) =>
  put(`${url.CAMPAIGNS}/wa/${waCampaignId}/${action}`);
export const postSendHSM = (waCampaignId, hsm) =>
  post(`${url.CAMPAIGNS}/wa/${waCampaignId}/hsm`, hsm);
export const deleteWaCampaign = waCampaignId =>
  del(`${url.CAMPAIGNS}/wa/${waCampaignId}`);
export const putWaCampaignColor = (waCampaignId, color) =>
  put(`${url.CAMPAIGNS}/wa/${waCampaignId}`, { color });
export const getSessionsFromMonitor = (
  isActive,
  lastKey,
  campaign,
  assignedTo,
) =>
  get(
    `${url.CAMPAIGNS}/wa/monitor/status?${
      isActive !== undefined ? `isActive=${isActive}&` : ''
    }${campaign ? `campaign=${campaign}&` : ''}${
      assignedTo ? `assignedTo=${assignedTo}&` : ''
    }${lastKey ? `lastKey=${lastKey}` : ''}`,
  );

export const getGupshupAppList = () => get(`${url.GUPSHUP_APP}/list`);
export const getGupshupAppTemplates = appId =>
  get(`${url.GUPSHUP_APP}/${appId}/template`);

// Reports
export const getReportTypesRequest = owner =>
  getAws(`${url.REPORTS}/types/owner/${owner}`);
export const getReportCalls = (owner, start_date, end_date, queue) =>
  getAws(
    `${url.REPORTS_CALLS}/owner/${owner}?start=${start_date}&end=${end_date}${
      queue ? `&queue=${queue}&excludeDuplicates=true` : ''
    }`,
  );
export const postReportRequest = (owner, start_date, end_date, reportTypeId) =>
  postAws(
    `${url.REPORTS}/type/${reportTypeId}/owner/${owner}?start=${start_date}&end=${end_date}`,
  );
export const postReportRequest_v2 = (
  owner,
  start_date,
  end_date,
  reportTypeId,
  filters,
) =>
  postAws(
    `${
      url.REPORTS
    }/v2/type/${reportTypeId}/owner/${owner}?start=${start_date}&end=${end_date}${
      filters ? `${filters}` : ''
    }`,
  );
export const getReport = (owner, reportId) =>
  getAws(`${url.REPORTS}/${reportId}/owner/${owner}`);

export const getEventFromBotData = (
  exten,
  start,
  end,
  limit = 1000,
  lastKey = '',
) =>
  get(
    `/call/exten/${exten}/event?asc=false&limit=${limit}&end=${end}&start=${start}${
      lastKey ? `&lastKey=${lastKey}` : ''
    }`,
  );
export const getEmailsReport = (lastKey, order, date, filter, limit = 100) =>
  get(
    `/email/session/log?limit=${limit}${order === 'asc' ? '&asc=true' : ''}${
      date.start ? `&start=${date.start}T00:00:00.000Z` : ''
    }${date.end ? `&end=${date.end}T23:59:59.999Z` : ''}${
      filter ? `&filter=${filter.filter}&filterValue=${filter.filterValue}` : ''
    }${lastKey ? `&lastKey=${lastKey}` : ''}`,
  );
export const getEmailSession = sessionId =>
  get(`/email/session/${sessionId}/log`);
export const getWhatsappUsage = (appId, from, to) =>
  get(`/gupshup/app/${appId}/usage?from=${from}&to=${to}`);

// Configuration
export const getAgentsInQueue = queueId =>
  get(`${url.CONFIGURATION_PBX}/queue/${queueId}/agent`);
export const updateAgentsInQueue = (queueId, agents) =>
  put(`${url.CONFIGURATION_PBX}/queue/${queueId}/agent`, agents);

//conversation
export const getAsignedSessions = channel =>
  get(`${url.CONVERSATION}/channel/${channel}/session/active`);
export const getUnassignedSessions = channel =>
  get(`${url.CONVERSATION}/channel/${channel}/session/unassigned`);
export const getUnassignedSystemSessions = channel =>
  get(`${url.CONVERSATION}/channel/${channel}/session/system/active/true`);

export const getClientMessages = (channel, source) =>
  get(`${url.CONVERSATION}/channel/${channel}/source/${source}`);
export const getSessionMessages = sessionId =>
  get(`${url.CONVERSATION}/session/${sessionId}/message`);
export const postSendMessage = (sessionId, message) =>
  post(`${url.CONVERSATION}/session/${sessionId}/message`, message);
export const delCloseSession = sessionId =>
  del(`${url.CONVERSATION}/session/${sessionId}`);
export const assingSessionToAgent = sessionId =>
  put(`${url.CONVERSATION}/session/${sessionId}/agent`);

export const getSessionLogs = sessionId =>
  get(`${url.CONVERSATION}/session/${sessionId}/log`);
export const transferSession = (sessionId, agentId) =>
  put(`${url.CONVERSATION}/session/${sessionId}/agent/${agentId}`);
export const getWaSession = sessionId =>
  get(`${url.CONVERSATION}/session/${sessionId}`);
export const restoreSession = sessionId =>
  put(`${url.CONVERSATION}/session/${sessionId}/restore`);

//channels
export const getChannels = () => get(`${url.CONVERSATION}/channel-v2`);
export const putUpdateChannelScenery = (channelId, scenery) =>
  put(`${url.CONVERSATION}/channel-v2/${channelId}`, scenery);

// ASESP callStatus
export const updateCallStatus = status => put('/asesp/call/status', status);

// scenarios V2
export const getScenariosV2 = () => get(`${url.SCENARIOSV2}`);
export const postSceneryV2 = scenery => post(`${url.SCENARIOSV2}`, scenery);
export const putUpdateSceneryV2 = (id, scenery) =>
  put(`${url.SCENARIOSV2}/${id}`, scenery);
export const getSceneryByIdV2 = id => get(`${url.SCENARIOSV2}/${id}`);
export const getSceneryFunctions = () =>
  get(`${url.SCENARIOSV2}/workflow/function`);
export const getSceneryIntents = () =>
  get(`${url.SCENARIOSV2}/workflow/intent`);

//databases
export const getAllDatabases = () => get(`/client/database`);
export const getRowsByDatabaseId = ({ dbId, limit, dbIndex, lastKey }) =>
  get(
    `/client/database/${dbId}/rows?limit=${limit}&index=${dbIndex}${
      lastKey ? `&lastKey=${lastKey}` : ''
    }`,
  );
export const getRowById = (dbId, rowId) =>
  get(`/client/database/${dbId}/rows/${rowId}`);
export const postCreateDb = db => post(`/client/database`, db);
export const putUpdateDb = (dbId, db) => put(`/client/database/${dbId}`, db);
export const postRowsDb = (dbId, rows) =>
  postNoDestructuring(`/client/database/${dbId}/rows`, rows);
export const deleteDbRows = (dbId, rowsId) =>
  del(`/client/database/${dbId}/rows`, { data: rowsId });
export const deleteDb = dbId => del(`/client/database/${dbId}`);

//files
export const getAllFiles = () => get(`/system/uploadfile?asc=true`);
export const getFileById = fileId => get(`/system/uploadfile/${fileId}`);
export const deleteFile = fileId => del(`/system/uploadfile/${fileId}`);
export const uploadfile = formData =>
  postNoDestructuring('/system/uploadfile', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

//users usuarios de whatsapp conectados
export const postUserConnection = () =>
  post('/users/session', { channelType: 'chat' });
export const deleteUserConnection = () =>
  del('/users/session', { data: { channelType: 'chat' } });

export const postSpyOption = (option, extenAgent) =>
  post(`/pbx/spy/${option}/agent/${extenAgent}`);
