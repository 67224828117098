import LoadingImg from 'components/Configuration/atoms/loading';
import { Completed, Draft, Megaphone, Pause, Phone } from 'icons';
import IStore from 'library/interfaces/storeInterface';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCampaignsAction,
  getCampaignByIdAction,
  getPBXCampaignInfoAction,
  getReportData,
} from 'store/actions';
import { ICampaign, IVoiceCampaignsStore } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import styled from 'styled-components';
import LeftMenuOutbound from '../molecules/leftMenu';

import SearchAndButton from '../molecules/searchAndButton';
import Campaign from '../organisms/campaign';

interface ICampaignsTemplateProps {
  setShowCampaignForm: (
    show: boolean,
    campaignToEdit?: null | ICampaign,
    campaignIndexToUpdate?: null | ICampaign,
  ) => void;
}
const CampaignsTemplate: FC<ICampaignsTemplateProps> = ({
  setShowCampaignForm,
}) => {
  const dispatch = useDispatch();
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const {campaigns, newCampaignId, postContactGroups} = useSelector<IStore, IVoiceCampaignsStore>(
    store => store.voiceCampaigns,
  );
  
  

  const [campaignFilter, setCampaignFilter] = useState('');
  
  const campaignsToRender = campaigns.filter(campaign =>
    campaignFilter ? campaign.status === campaignFilter : campaign,
  );

  const handleCreateCamgpaignButtonClick = () => {
    setShowCampaignForm(true);
  };

  const calcCreateContactsPorcent = (): number => {
    if(postContactGroups && postContactGroups.totalGroups >= 0){
      let { totalGroups, sendingGroup} = postContactGroups
      let porcent = ((sendingGroup / totalGroups) * 100)
  
      return isNaN(porcent) ? 0 : parseFloat(porcent.toFixed(2))
    }
    return 0
  }

  useEffect(() => {
    if (newCampaignId === '') return;
    dispatch(getCampaignByIdAction(newCampaignId));
  }, [newCampaignId]);

  useEffect(() => {
    if (!owner) return;
    dispatch(getAllCampaignsAction(owner));
    dispatch(getReportData());
    dispatch(getPBXCampaignInfoAction());
  }, [owner]);

  const hide = [
    "035b8d56-ec92-406d-abc6-86388651a1d4",
    "e0bbf26e-6321-438e-af4b-37f452fedded",
    "041fa8a5-2d6f-4520-8c30-e19d72366ff1",
    "d7f12c03-79ba-487e-b4d3-e209c331e80a",
    "d869299e-538c-4753-84a8-49c9837757a4",
    "9142d16d-c20c-4fb3-b3b4-df6503c9c029",
    "ea16d43d-803b-4305-a4b1-f4e36ac39e4a",

    "f477051b-3277-4f98-9900-8f2e38d59d9e",
    "1d61d8ae-54ec-4ea1-93e7-e0b957f89197",
    "6e64017f-2edb-4a6a-ad81-4beeaf29f30c",
    "f18d7f50-9571-44cd-a2b8-668762629b94",
    "f534d8f2-0814-48c9-97d4-a22c1cbe5920",
    "b726e92e-214d-4155-adc5-8df32d3633d6",
    "e0e1d9f3-4952-428f-a4c7-5de58e146f0f",
    "1d460bca-6ca3-4ccf-83fb-d8858dd6a716",
    "4fde5123-fbf3-4221-9fb2-3b1bfd40cc66",
    "8e3c20b3-ef59-4265-857d-7f1d136bfa47",
    "cb160360-1c56-4cd8-9bb3-5ef3763b1d92",
    "9cd81bf4-a60a-4e4d-94d3-8ad952e4583b",
    "a6865be2-9492-411a-af2d-24080dc9ffa5",
    "94698f7a-594c-4374-ae77-7c2b219bbca2",
    "61ef302f-e1a8-4ea3-8e5a-c44fcff4269b",
    "85396111-88fe-4e8e-9707-7fd7f0ca3a16",
    "830bc441-237a-43c2-b807-70a110213fd3",
    "5a83930a-28b6-41bd-b3ed-83c37d40c289",
    "eb5721de-f6f8-42bd-9b1d-3d1496886caf",
    "04c0113d-508d-429a-88ff-18bedff0177f",
    "65935c5a-3168-4f3b-bb60-4de1a364f9d2",
    "e41bc7b3-b7ae-40d0-a9ba-525d51a911dc",
    "9e952c72-2bc3-4706-b241-5faeeddea563",
    "cfa22198-9467-4ebc-941e-d9984677c28d",
    "44ea605a-8cfe-433d-937c-0ea62d4273ac",
    "71d5e27d-fb39-4c33-974f-8a12cf7b3fe4",
    "476f5e7d-4cec-4cad-a219-89597960ef5a",
    "5af0afec-f9e4-4911-ad92-e60357aa7103",
    "6fb264f3-7aec-4c44-8ffb-10d68068fedf",
    "fdd693a2-de21-4320-8fcc-63734731e337",
    "f8542182-374e-406b-a51d-13afa08aaa51",
    "65f1eb24-e588-4d69-90f4-857cfa28a05b",
    "dc260409-6246-4b73-844a-5c0effd561a2",
    "e58ccf2d-99ab-4406-a4ba-0154a8d84906",
    "b7d614b1-0896-4c21-8388-574b8a8f48c2",
    "f1c9c5d3-4b5c-4bf5-b889-672c3db18b42",
    "082eb873-941b-42d9-9e46-a222f5a77b2f",
    "bdfc0a29-cd71-40bf-a048-e5e8aebf1a23",
    "69ff22d1-7782-4acb-811f-e992ec782d77",
    "bd54ad95-fe81-47c1-99ff-ec98371a30b8",
    "28715315-f1df-4bb0-b089-118ba6c93697",
    "f4821f18-452f-478f-bd00-2a438eb07a78"
]
  return (
    <Cont>
      <SearchAndButton onButtonClick={handleCreateCamgpaignButtonClick} />
      <FlexCont>
        <LeftMenuOutbound filterButtons={buttons} setCampaignFilter={setCampaignFilter}/>
        <CampaignsCont>
          {campaignsToRender.map((campaign, index) => {
            if(hide.includes(campaign.id)) return
            return (
              <Campaign
                key={index}
                campaign={campaign}
                campaignIndex={index}
                setShowCampaignForm={setShowCampaignForm}
              />
            );
          })}
        </CampaignsCont>
      </FlexCont>
      {postContactGroups && <LoadingImg blur={2} position='fixed' porcent={calcCreateContactsPorcent()}/>}
    </Cont>
  );
};
const Cont = styled.div`
  margin: 0 20px;
  position: relative;
`;
const FlexCont = styled.div`
  display: flex;
`;
const CampaignsCont = styled.div`
  flex-grow: 1;
`;
//
// filter buttons 
//
const buttons = [
  {
    isSelected: true,
    icon: < Megaphone />,
    value: '',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.all' },
  },
  {
    isSelected: false,
    icon: < Draft width={15}/>,
    value: 'draft',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.draft' },
  },
  {
    isSelected: false,
    icon: < Phone width={17}/>,
    value: 'running',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.ongoing' },
  },
  {
    isSelected: false,
    icon: < Pause width={25} style={{transform: 'translateX(-5px)'}}/>,
    value: 'paused',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.pause' },
  },
  {
    isSelected: false,
    icon: < Completed width={18}/>,
    value: 'completed',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.completed' },
  },
]
export default CampaignsTemplate;
